<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm  align-items-center">
            <img src="assets/images/logo-light1.svg" alt="" height="18">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="45">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-xl-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->

    </div>

    <div class="d-flex">
      <!-- <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" dropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      <!-- COORDINACIÓN ACTIVO -->
      <div class="dropdown d-inline-block d- ms-2" dropdown (click)="stopPropagation($event)">
        <button type="button" class="btn header-item noti-icon d-flex align-items-center text-primary" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" dropdownToggle aria-expanded="false"  (click)="openModalGroup()">
          <!-- <i class="mdi mdi-magnify"></i> -->
          <i class="mdi mdi-account-multiple text-primary"></i>
          <span class="ms-2 d-none d-sm-block">{{nameGroupSelected}}</span>
        </button>
        <!-- <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" *dropdownMenu
          aria-labelledby="page-header-search-dropdown">
          <form class="p-3">
            <div class="m-0">
              <select name="group_option" id="group_option" class="custom-select form-control" [(ngModel)]="groupIdSelected" (change)="onChangeGroup($event)">
                <option value="" *ngIf="dataUser.tipo_usuario == 'PROPIETARIO' || dataUser.tipo_usuario == 'SISTEMA' || dataUser.tipo_usuario == 'ADMINISTRADOR'">{{'FORMS.ALL' | translate}}</option>
                <option [value]="group.id" *ngFor="let group of listGroup">{{group.nombre}}</option>
                <option value="" *ngIf="listGroup.length == 0">{{'FORMS.NO_RECORD.ITEMS' | translate}}</option>
              </select>
            </div>
          </form>
        </div> -->
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" dropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12">
            <span class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block d-none" dropdown>
        <button type="button" class="btn header-item noti-icon position-relative"
          id="page-header-notifications-dropdown" dropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" *dropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{{
                'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" dropdown>
        <button type="button" class="btn header-item" dropdownToggle id="page-header-user-dropdown">
          <!-- <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"> -->
          <img *ngIf="!dataUser || !dataUser?.foto_perfil" class="rounded-circle header-profile-user" src="assets/images/jobs.png" alt="Header Avatar">
          <img *ngIf="dataUser?.foto_perfil" [src]="PATH_URL_FILES + '/' + dataUser?.foto_perfil" class="rounded-circle header-profile-user" alt="Usuario">
          <span class="d-none d-xl-inline-block ms-1">{{dataUser?.person?.nombres}} {{dataUser?.person?.apellido_paterno}} {{dataUser?.person?.apellido_materno}} - {{dataUser?.type_user?.nombre}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <!-- <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a> -->
          <a class="dropdown-item" href="javascript: void(0);" (click)="lockScreen()" ><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>

    </div>
  </div>
</header>


<div bsModal #center="bs-modal" class="modal fade" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
          <div class="modal-header border-bottom-0">
              <button type="button" class="btn-close" aria-label="Close" (click)="center.hide()"> </button>
          </div>
          <div class="modal-body">
              <div class="text-center mb-4">
                  <div class="avatar-md mx-auto mb-4">
                      <div class="avatar-title bg-light rounded-circle text-primary h1">
                          <!-- <i class="mdi mdi-email-open"></i> -->
                          <i class="mdi mdi-account-multiple-check"></i>
                      </div>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-xl-10">
                          <h4 class="text-primary">Coordinación!</h4>
                          <p class="text-muted font-size-14 mb-4">
                            Por favor seleccione una coordinación
                          </p>

                          <div class="input-group bg-light rounded">                              
                              <ng-select id="group_option" style="width: 90%; flex-grow: 1;"
                                [loading]="loadingGroup" 
                                [loadingText]="'Cargando...'" [minTermLength]="2"
                                [notFoundText]="'No se encontraron elementos'"
                                [(ngModel)]="groupIdSelected"
                              >
                                <ng-option value="" *ngIf="listGroup.length > 0 && dataUser?.tipo_usuario == 'PROPIETARIO' || dataUser?.tipo_usuario == 'SISTEMA' || dataUser?.tipo_usuario == 'ADMINISTRADOR'">{{'FORMS.ALL.ITEMS' | translate}}</ng-option>
                                <ng-option *ngFor="let group of listGroup" [value]="group.id">{{group.nombre}}</ng-option>
                                <ng-option value="" *ngIf="listGroup.length == 0">{{'FORMS.NO_RECORD.ITEMS' | translate}}</ng-option> 
                              </ng-select>

                              <button class="btn btn-primary" type="button" id="button-addon2" (click)="onSelectGroup()">
                                  <i class="mdi mdi-check-bold"></i>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>