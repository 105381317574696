import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string | SafeHtml | undefined, search: string): SafeHtml {
    if (!value || !search) {
      return value || ''; // Si no hay valor o búsqueda, devuelve el texto tal cual
    }

    // Convertir SafeHtml a string si es necesario
    const rawValue = typeof value === 'string' ? value : this.safeHtmlToString(value);

    // Parsear el HTML en un documento manipulable
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawValue, 'text/html');

    // Aplicar resaltado dentro del contenido visible, sin alterar etiquetas <style> y <script>
    this.highlightTextNodes(doc.body, search);

    // Retornar el HTML con resaltado, manteniendo estilos
    return this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
  }


  /**
* Aplica resaltado a los nodos de texto dentro del HTML, sin modificar la estructura ni estilos.
*/
  private highlightTextNodes(node: Node, search: string) {
    const regex = new RegExp(`(${this.escapeRegex(search)})`, 'gi');

    // Recorre los nodos hijos recursivamente
    for (let i = 0; i < node.childNodes.length; i++) {
      const child = node.childNodes[i];

      if (child.nodeType === Node.TEXT_NODE) {
        // Si es un nodo de texto, reemplazar la coincidencia por un <span>
        const text = child.nodeValue || '';
        if (regex.test(text)) {
          const highlightedText = text.replace(regex, `<span class="highlight">$1</span>`);
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = highlightedText;

          // Insertar el nuevo contenido resaltado
          while (tempDiv.firstChild) {
            node.insertBefore(tempDiv.firstChild, child);
          }
          node.removeChild(child);
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        // Si es un elemento, evitar etiquetas <style> y <script>
        const tagName = (child as HTMLElement).tagName.toLowerCase();
        if (tagName !== 'style' && tagName !== 'script') {
          this.highlightTextNodes(child, search); // Llamado recursivo
        }
      }
    }
  }

  /**
 * Extrae solo el texto visible del HTML, eliminando etiquetas <style> y <script>.
 */
  private extractTextContent(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Eliminar etiquetas <style> y <script>
    doc.querySelectorAll('style, script').forEach((el) => el.remove());

    return doc.body.textContent || '';
  }

  /**
   * Convierte un SafeHtml en string.
   */
  private safeHtmlToString(safeHtml: SafeHtml): string {
    // Crear un contenedor temporal para convertir SafeHtml a string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = safeHtml as string;
    return tempDiv.innerHTML;
  }

  /**
   * Escapa caracteres especiales en una cadena para usarlos en una expresión regular.
   */
  private escapeRegex(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
