import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { CanMatchFn } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginComponent } from 'src/app/account/auth/login/login.component';
import { AccessControlService } from '../services/auth/accessControl.service';


export const hasRoleGuard: CanActivateFn = (route, segments) => {
    const router = inject(Router);
    const accessControlService = inject(AccessControlService);
    const url = segments.url;
    const expectedRoles = route?.data?.roles;

    // CONDICIONANDO LA URL 
    if (!accessControlService.isLoggedIn() || !accessControlService.userHasAccess(url)) {
        router.navigate(['/main']);
        return false;
    } 
    
    return true

    // CONDICIONANDO EL TIPO DE USUARIO Y LOS ROLES INDICADOS EN EL GUARD
    // if (!accessControlService.isLoggedIn() || !accessControlService.hasAnyPermission(expectedRoles)) {
    //     // No autenticado o no tiene ninguno de los roles esperados, redirige a la página de inicio de sesión
    //     router.navigate(['/main']);
    //     return false;
    // }

    // return true;
};
