<!-- <ul class="metismenu list-unstyled">
</ul> -->
<li class="menu-title placeholder-glow">
  <p class="placeholder placeholder-sm bg-primary w-100">Hola</p>
</li>

<ng-container *ngFor="let itemRow of getRange(rows); let iRow = index">
  <li *ngIf="iRow % 2 === 0">
    <a routerLink="/" class="side-nav-link-ref">
      <i class="placeholder"></i>
      <span class="placeholder placeholder-sm"></span>
      <span class="badge rounded-pill bg-secondary float-end placeholder">
        <p class="placeholder rounded"></p>
      </span>
    </a>
  </li>
  <li *ngIf="iRow % 2 !== 0">
    <a href="javascript:void(0);" class="is-parent has-arrow">
      <i class=""></i>
      <span class="placeholder col-sm-2"></span>
      <span class="badge rounded-pill float-end bg-secondary">
        <p class="placeholder rounded col-4"></p>
      </span>
    </a>

    <ul class="sub-menu" aria-expanded="false" *ngIf="showSubItems">
      <li>
        <a routerLink="/" class="side-nav-link-ref">
          <p class="placeholder placeholcer-sm col-5"></p>
          <span class="badge rounded-pill bg-secondary float-end">
            <p class="placeholder rounded"></p>
          </span>
        </a>
      </li>
    </ul>
  </li>
</ng-container>
