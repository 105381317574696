import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-placeholder-card',
  templateUrl: './placeholder-card.component.html',
  styleUrls: ['./placeholder-card.component.scss']
})
export class PlaceholderCardComponent implements OnInit {

  @Input() rows: number = 1;
  @Input() columns: number = 0;
  @Input() showButtons: boolean = true;
  loadRandom: number = 0;


  /**
* OBTENER RANGO A PARTIR DE UN VALOR
* @param value 
* @returns 
*/
  getRange(value: number): number[] {
    return Array.from({ length: value }, (_, index) => index);
  }

  ngOnInit(): void {
    this.loadRandom = Math.floor(Math.random() * 100) + 1;
  }


}
