import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiErrorFormattingService, FormService, SweetAlertService } from 'src/app/core/services';


class ModelJson{
  public clave: string;
  public valor: any;

  constructor(data?: object){
    Object.assign(this, data);
    this.clave = this.clave || '';
    this.valor = this.valor || null;
  }

  public static cast(data: object){
    const obj = new ModelJson(data);
    return {
      clave: obj.clave,
      valor: obj.valor
    }
  }

  public static casts(data: any[]){
    return data.map((obj) => ModelJson.cast(obj))
  }
}

@Component({
  selector: 'app-form-data-json',
  templateUrl: './form-data-json.component.html',
  styleUrls: ['./form-data-json.component.scss']
})
export class FormDataJsonComponent implements OnInit, OnDestroy, OnChanges {

  // Datos de entrada
  @Input() data: any[] = [];
  @Input() submitted: boolean = false;

  // Datos de salida
  @Input() hiddenButtons: boolean = false;
  @Output() submit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  // MOSTRAR LISTA
  showList: boolean = true;
  isNewData: boolean = true;
  // submitted: boolean = false;
  dataJsonForm: FormGroup;


  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private _formService: FormService,
    private _apiErrorFormattingService: ApiErrorFormattingService,
    private _sweetAlertService: SweetAlertService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.dataJsonForm = this.formBuilder.group({
      formList: this.formBuilder.array([]),
    }),

    // this.formDataList.push(this.newField());
    
    this.onChangeData();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      this.onChangeData();
    }
  }


  // DATOS EMITIDOS
  onChangeData() {
    if (this.dataJsonForm) {
      this.dataJsonForm = this.formBuilder.group({
        formList: this.formBuilder.array([]),
      })

      if (this?.data?.length > 0) {
        this.data.forEach((item) => {
          const formGroup = this.newField(ModelJson.cast(item));
          // if (item?.reverso_documento) {
          //   formGroup.patchValue({ show_reverse: true });
          // } else {
          //   formGroup.patchValue({ show_reverse: false });
          // }
          this.formDataList.push(formGroup);

          // if (item?.reverso_documento) {
          //   item.show_reverse = true;
          // } else {
          //   item.show_reverse = false;
          // }
          // this.formDataIdentification.push(this.fieldIdentification(IdentificationDocument.cast(item)));
          this.cdr.detectChanges()
        })
        this.isNewData = false;
      } else {
        // this.formDataList.push(this.newField());
        this.isNewData = true;
      }
    }
  }


  /**
 * *******************************************************
 * AGREGAR MÁS CAMPOS DE TIPO Y DOCUMENTO
 * *******************************************************
 */
  newField(model: ModelJson = new ModelJson()): FormGroup {
    const formGroup = this.formBuilder.group({
      ...this._formService.modelToFormGroupData(model),
      clave: [model?.clave || '', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      valor: [model?.valor || null, [Validators.required, Validators.minLength(1), Validators.maxLength(999)]],
    });

    // Agrega la validación personalizada para evitar duplicados
    // formGroup.setValidators(this.duplicateValidator());
    return formGroup;
  }

  get formDataList(): FormArray {
    return this.dataJsonForm.get('formList') as FormArray;
  }

  // OCULTAR BOTON DE CERRAR
  get visibleCloseBtn() {
    let minItems = 0;
    return this.formDataList.length > minItems;
  }

  // ELIMINAR UN OBJETO 
  removeField(i: number) {
    this.formDataList.removeAt(i);
  }

  // AÑADIR NUEVO OBJETO 
  addField() {
    this.showList = true;
    this.formDataList.push(this.newField());
  }

  
  /**
 * ************************************************************
 * EMITIR EL VALOR DEL FORMULARIO
 * ************************************************************
 */
  onSubmit() {
    this.submitted = true;
    if (this.formDataList.invalid) {
      this._sweetAlertService.showTopEnd({ title: 'Validación de datos', message: 'Campos obligatorios vacíos', type: 'warning', timer: 1500 });
      this.submit.emit({ emit: true, values: [] });
    } else {
      const values = this.formDataList.value;
      console.log(values)
      this.submit.emit({ emit: true, values });
    }
  }

  onCancel() {
    this.onReset();
    this.cancel.emit({ message: 'Cancelado' });
  }

  onReset() {
    this.submitted = false;
    this.isNewData = true;
    this.formDataList.reset();
    this.formDataList.clear();
    this.formDataList.push(this.newField());
  }

}
