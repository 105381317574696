<form>
  <div class="custom-dropzone">
    <div class="d-flex justify-content-start align-items-center flex-row flex-wrap gap-2 mb-3" *ngIf="countedFileByType">
      <button type="button" class="btn btn-sm btn-outline-primary rounded-pill" *ngIf="countedFileByType?.video" [ngClass]="{'active': activeFilter == 'video'}" (click)="filterByType('video')">
        Videos <span class="badge bg-primary ms-1 rounded-pill">{{countedFileByType?.video || 0}}</span>
      </button>
      <button type="button" class="btn btn-sm btn-outline-success rounded-pill" *ngIf="countedFileByType?.image" [ngClass]="{'active': activeFilter == 'image'}" (click)="filterByType('image')">
        Imágenes <span class="badge bg-success ms-1 rounded-pill">{{countedFileByType?.image || 0}}</span>
      </button>
      <button type="button" class="btn btn-sm btn-outline-warning rounded-pill" *ngIf="countedFileByType?.audio" [ngClass]="{'active': activeFilter == 'audio'}" (click)="filterByType('audio')">
        Audios <span class="badge bg-warning ms-1 rounded-pill">{{countedFileByType?.audio || 0}}</span>
      </button>
      <button type="button" class="btn btn-sm btn-outline-danger rounded-pill" *ngIf="countedFileByType?.document" [ngClass]="{'active': activeFilter == 'document'}" (click)="filterByType('document')">
        Documentos <span class="badge bg-danger ms-1 rounded-pill">{{countedFileByType?.document || 0}}</span>
      </button>
      <button type="button" class="btn btn-sm btn-outline-secondary rounded-pill" *ngIf="countedFileByType?.other" [ngClass]="{'active': activeFilter == 'other'}" (click)="filterByType('other')">
        Otros <span class="badge bg-secondary ms-1 rounded-pill">{{countedFileByType?.other || 0}}</span>
      </button>
    </div>
    <ngx-dropzone class="dropzone" (change)="onFilesSelect($event)" [accept]="'*'">
      <div class="container-preview mb-3">
        <div class="row g-2" >
          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngFor="let f of uploadPreviewFilter; index as i" >
            <ngx-dropzone-preview  class="rounded dropzone-preview" style="z-index: 100;" [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label (click)="$event.stopPropagation()">
                <div class="d-flex flex-column p-2">
                  <!-- Previsualización dinámica -->
                  <div class="content-preview bg-light rounded d-flex align-items-center justify-content-center" >
                    <ng-container [ngSwitch]="f.type">
                      <!-- Imagen -->
                      <div class="content-img bg-light rounded" *ngSwitchCase="'image'">
                        <img class="img-fluid object-fit-contain rounded" style="cursor: pointer;" [src]="f.src" [alt]="f?.file?.name" (click)="showFileLightBox(i)">
                      </div>
                      <!-- Video -->
                      <video *ngSwitchCase="'video'" class="img-fluid object-fit-contain rounded" controls>
                        <source [src]="f.src" [type]="f?.file?.type">
                        Su navegador no soporta la etiqueta de video.
                      </video>
                      <!-- Audio -->
                      <audio *ngSwitchCase="'audio'" controls>
                        <source [src]="f.src" [type]="f?.file?.type">
                        Su navegador no soporta la etiqueta de audio.
                      </audio>
                      <!-- Documento -->
                      <i *ngSwitchCase="'document'" class="display-4 text-primary bx bxs-file"></i>
                      <!-- Otros -->
                      <i *ngSwitchDefault class="display-4 text-secondary bx bxs-file"></i>
                    </ng-container>
                  </div>
  
                  <!-- Información del archivo -->
                  <div class="pt-2 text-center">
                    <strong class="text-break d-block">{{ f?.file?.name }}</strong>
                    <small class="text-muted">{{ formatFileSize(f?.file?.size) }}</small>
                  </div>
                </div>
              </ngx-dropzone-label>
            </ngx-dropzone-preview>
          </div>
        </div>
      </div>

      <ngx-dropzone-label class="dropzone-label" style="align-self: flex-end; width: 100% !important;">
        <div class="mb-2">
          <i class="display-4 bx bxs-cloud-upload"></i>
        </div>
        <h5 class="">{{'FORMS.FILES.DROP_OR_CLICK_UPLOAD.label' | translate}}</h5>
      </ngx-dropzone-label>
    </ngx-dropzone>
  </div>
</form>