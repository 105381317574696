<div class="table-responsive"> 
    <table class="table align-middle dt-responsive nowrap w-100 table-check">
      <thead>
        <tr class="placeholder- ">
            <th scope="col" class="placeholder-" [ngClass]="{'placeholder-wave': iColumn % 2 === 0, 'placeholder-glow': iColumn % 2 !== 0}" *ngFor="let itemColumn of getRange(columns); let iColumn = index">
                <h5 class="rounded placeholder-lg bg-primary w-100" [ngClass]="{'placeholder': iColumn % 2 === 0}"></h5>
            </th>
          <!-- <th scope="col" class="placeholder-wave"><h5 class="rounded placeholder-lg bg-primary w-100"></h5></th>
          <th scope="col" class="placeholder-glow"><h5 class="rounded placeholder placeholder-lg bg-primary w-100"></h5></th>
          <th scope="col" class="placeholder-wave"><h5 class="rounded placeholder-lg  bg-primary w-100"></h5></th>
          <th scope="col" class="placeholder-glow"><h5 class="rounded placeholder placeholder-lg bg-primary w-100"></h5></th>
          <th scope="col" class="placeholder-glow"><h5 class="rounded placeholder-lg bg-primary w-100"></h5></th> -->
          <th scope="col" class="placeholder-wave" style="width: 50px;" *ngIf="showButtons"><h5 class="rounded placeholder placeholder-lg bg-primary w-100"></h5></th>
        </tr>
      </thead>
      <tbody *ngFor="let itemRow of getRange(rows); let iRow = index">          
        <ng-template #evenAndOdd>
            <!-- PARES -->
            <tr *ngIf="iRow % 2 === 0">
                <td class="placeholder-" [ngClass]="{'placeholder-wave': iColumn % 2 === 0, 'placeholder-glow': iColumn % 2 !== 0}" *ngFor="let itemColumn of getRange(columns); let iColumn = index">
                    <p class="rounded placeholder placeholder-sm bg- w-100" [ngClass]="{'placeholder-xs': iColumn === 2}"></p>
                </td>


                <!-- <td class="placeholder-glow"><p class="rounded placeholder placeholder-md bg- w-100" ></p></td>
                <td class="placeholder-wave"><p class="rounded placeholder placeholder-lg bg-secondary w-100" ></p></td>
                <td class="placeholder-glow"><p class="rounded placeholder placeholder-sm bg-secondary w-100" ></p></td>
                <td class="placeholder-wave"><p class="rounded placeholder placeholder-sm bg-secondary w-100" ></p></td>
                <td class="placeholder-glow"><p class="rounded placeholder placeholder-sm bg-secondary w-100" ></p></td> -->
                <td class="placeholder-glow" *ngIf="showButtons">
                    <div>
                        <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Detalle">
                            <a href="javascript:void(0);" class="btn btn-sm bg-primary disabled placeholder" style="width: 28px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="placeholder-glow">
                            <a href="javascript:void(0);" class="btn btn-sm bg-info disabled placeholder" style="width: 25px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" class="placeholder-glow">
                            <a href="javascript:void(0);" data-bs-toggle="modal" class="btn btn-sm bg-danger placeholder" style="width: 25px;"></a>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            
            <!-- IMPARES -->
            <tr *ngIf="iRow % 2 !== 0">
                <td class="placeholder-glow" [ngClass]="{'placeholder-wave': iColumn % 2 === 0, 'placeholder-glow': iColumn % 2 !== 0}" *ngFor="let x of getRange(columns); let indexC = index">
                    <p class="rounded placeholder placeholder-lg bg- w-100" [ngClass]="{'placeholder-sm': iColumn === 1}"></p>
                </td>
                <!-- <td class="placeholder-glow"><p class="rounded placeholder placeholder-xs bg- w-100" ></p></td>
                <td class="placeholder-wave"><p class="rounded placeholder placeholder-sm bg-secondary w-100" ></p></td>
                <td class="placeholder-glow"><p class="rounded placeholder placeholder-xs bg-secondary w-100" ></p></td>
                <td class="placeholder-wave"><p class="rounded placeholder placeholder-xs bg-secondary w-100" ></p></td>
                <td class="placeholder-glow"><p class="rounded placeholder placeholder-xs bg-secondary w-100" ></p></td> -->
                <td class="placeholder-wave" *ngIf="showButtons">
                    <div>
                        <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Detalle">
                            <a href="javascript:void(0);" class="btn btn-sm bg-primary disabled placeholder" style="width: 28px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="placeholder-glow">
                            <a href="javascript:void(0);" class="btn btn-sm bg-info disabled placeholder" style="width: 25px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" class="placeholder-glow">
                            <a href="javascript:void(0);" data-bs-toggle="modal" class="btn btn-sm bg-danger placeholder" style="width: 25px;"></a>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </ng-template>

        <!-- Contenido para los dos últimos elementos -->
         <ng-container *ngIf="rows > 1 && (iRow === rows - 2 || iRow === rows - 1); else evenAndOdd">
             <tr *ngIf="rows > 1 && (iRow === rows - 2)">
                <td class="placeholder-" [ngClass]="{'placeholder-wave': iColumn % 2 === 0, 'placeholder-glow': iColumn % 2 !== 0}" *ngFor="let itemColumn of getRange(columns); let iColumn = index">
                    <p class="rounded placeholder placeholder-xs bg- " [ngClass]="{'placeholder-xs col-9': iColumn % 2 === 0, 'placeholder-sm col-11': iColumn % 2 !== 0}"></p>
                </td>
                 <!-- <td class="placeholder-glow"><p class="placeholder placeholder-sm placeholder w-100 rounded"></p></td>
                 <td class="placeholder-wave"><p class="placeholder placeholder-sm col-9 rounded"></p></td>
                 <td class="placeholder-glow"><p class="placeholder w-100 rounded"></p></td>
                 <td class="placeholder-wave"><p class="placeholder placeholder-xs col-11 rounded"></p></td>
                 <td class="placeholder-glow"><p class="placeholder placeholder-xs w-100 rounded"></p></td> -->
                 <td class="placeholder-wave" *ngIf="showButtons">
                     <div>
                        <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Detalle">
                            <a href="javascript:void(0);" class="btn btn-sm bg-primary disabled placeholder" style="width: 28px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="placeholder-glow">
                            <a href="javascript:void(0);" class="btn btn-sm bg-info disabled placeholder" style="width: 25px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" class="placeholder-glow">
                            <a href="javascript:void(0);" data-bs-toggle="modal" class="btn btn-sm bg-danger placeholder" style="width: 25px;"></a>
                            </li>
                        </ul>
                     </div>
                 </td>
             </tr>
             <tr *ngIf="rows > 1 && (iRow === rows - 1)">
                <td class="placeholder-" [ngClass]="{'placeholder-glow': iColumn % 2 === 0, 'placeholder-wave': iColumn % 2 !== 0}" *ngFor="let itemColumn of getRange(columns); let iColumn = index">
                    <!-- <p class="rounded placeholder placeholder-md bg- w-100" ></p> -->
                    <p class="rounded placeholder placeholder-xs" [ngClass]="{'placeholder-sm col-11': iColumn % 2 === 0, 'placeholder-xs col-7': iColumn % 2 !== 0}"></p>
                </td>
                <!-- <td class="placeholder-glow"><p class="placeholder placeholder-sm placeholder col-10 rounded"></p></td>
                <td class="placeholder-glow"><p class="placeholder placeholder-sm col-11 rounded"></p></td>
                <td class="placeholder-glow"><p class="placeholder placeholder-xs col-11 rounded"></p></td>
                <td class="placeholder-glow"><p class="placeholder placeholder-dm col-9 rounded"></p></td>
                <td class="placeholder-glow"><p class="placeholder placeholder-xs col-5 rounded"></p></td> -->
                <td class="placeholder-glow" *ngIf="showButtons">
                    <div>
                        <ul class="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Detalle">
                            <a href="javascript:void(0);" class="btn btn-sm bg-primary disabled placeholder" style="width: 28px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" class="placeholder-glow">
                            <a href="javascript:void(0);" class="btn btn-sm bg-info disabled placeholder" style="width: 25px;"></a>
                            </li>
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" class="placeholder-glow">
                            <a href="javascript:void(0);" data-bs-toggle="modal" class="btn btn-sm bg-danger placeholder" style="width: 25px;"></a>
                            </li>
                        </ul>
                    </div>
                </td>
             </tr>
         </ng-container>
      </tbody>
    </table>
    <div class="w-100 px-3 placeholder-glow">
        <progressbar [value]="loadRandom" [max]="98" [striped]="true" [type]="'primary'" [animate]="true" class="progress-striped placeholder active"></progressbar>
        <div class="text-center mt-2"><h5 class="font-size-13">Cargando...</h5></div>        
    </div>
  </div>