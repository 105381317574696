import { HttpEventType, HttpProgressEvent } from "@angular/common/http";
import { Company } from "./company.model";
import { IdentificationDocument, IdentificationDocumentList } from "./identification-document.model";
import { TypeUser } from "./maintenance";
import { Model } from "./model";
import { Person } from "./person.model";

export class Progress extends Model {
  public progress: number;
  public timeRemaining: number;
  public type: HttpEventType;


  constructor(data?: object) {
    super(data);
    this.progress = this.progress || 0;
    this.timeRemaining = this.timeRemaining || null;
    this.type = this.type || null;
 
  }

  public static cast(data: object): Progress {
    return new Progress(data);
  }

  public static casts(dataArray: object[]): Progress[] {
    return dataArray.map((data) => Progress.cast(data));
  }
}

