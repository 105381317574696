import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import MetisMenu from 'metismenujs';
import { EventService } from '../../core/services/config/event.service';
import { Router, NavigationEnd } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';
import { AccessControlService } from '../../core/services/auth/accessControl.service';
import { isArray } from 'ngx-bootstrap/chronos';
import { SidebarList } from 'src/app/core/models/api/settings';
import { SidebarService } from 'src/app/core/services/api/settings';
import { SocketService } from 'src/app/core/services/shared/socket.service';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { ResponseApi, SocketModel } from 'src/app/core/models';
import { SweetAlertService } from 'src/app/core/services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  authAccess: any;


  menuItems = [];
  listSidebar: SidebarList[] = [];
  submenuOpenStates: { [key: string]: boolean } = {};

  @ViewChild('sideMenu') sideMenu: ElementRef;

  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private _sidebarService: SidebarService,
    private _socketService: SocketService,
    private _sweetAlertService: SweetAlertService,
    private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // console.log("EVENTO -> MENU:", event)
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }

  ngOnInit() {
    this.initialize();
    this.apiSidebarList()
    
    this._scrollElement();
    this.listenSocket();

    this.subscription.add(
      this._sidebarService.listObserver$
        .subscribe((list: SidebarList[]) => {
          this.listSidebar = list;
          
          setTimeout(() => {
            // this._activateMenuDropdown();
            this._scrollElement();
            this._removeAllClass('mm-active');
            this._removeAllClass('mm-show');
            this.cdr.detectChanges();
          }, 300);
          // this.menuItems = list;
          // console.log(list)
          // console.log(this.menuItems)
          // this.menuItems = MENU ;
        })
    );
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }



  /**
 * *************************************************************************************
 * DETECTAR CAMBIOS - SOCKET
 * *************************************************************************************
 */
  listenSocket() {
    this.subscription.add(
      this._socketService.outEven.subscribe((data: SocketModel) => {
        switch (data.process) {
          case 'sidebar':
            const row = data.content;
            // NUEVO
            if (data.operation == 'register') {
              // this.apiSidebarList()
            }

            // MODIFICADO
            if (data.operation == 'modify') {
              // this.apiSidebarList()
            }

            // ELIMINADO
            if (data.operation == 'delete') {
              // this.apiSidebarList()
            }
            break;

          default:
            break;
        }
      })
    );
  }

  /**
   * ****************************************************************
   * OPERACIONES CON LA API
   * ****************************************************************
   */
  public apiSidebarList(forceRefresh: boolean = false) {
    // this._sweetAlertService.loadingUp('Obteniendo datos')
    this._sidebarService.getAll(forceRefresh).subscribe((response: ResponseApi) => {
      this._sweetAlertService.stop();
      if (response.code == 200) {
        // this.listSidebar = response.data;        
      }

      if (response.code == 500) {
        if (response.errors) {
          this._sweetAlertService.showTopEnd({ type: 'error', title: response.errors?.message, message: response.errors?.error });
        }
      }
    }, (error: any) => {
      this._sweetAlertService.stop();
      console.log(error);
    });
  }



  toggleMenu(event: any) {
    event.currentTarget.nextElementSibling.classList.toggle('mm-show');
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0]['offsetTop'];
        if (currentPosition > 500)
          if (this.scrollRef.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this._removeAllClass('mm-active');
    this._removeAllClass('mm-show');
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]['pathname']);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf('/');
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add('active');
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('mm-active');
        const parent2El = parentEl.parentElement.closest('ul');
        if (parent2El && parent2El.id !== 'side-menu') {
          parent2El.classList.add('mm-show');
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== 'side-menu') {
            parent3El.classList.add('mm-active');
            const childAnchor = parent3El.querySelector('.has-arrow');
            const childDropdown = parent3El.querySelector('.has-dropdown');
            if (childAnchor) { childAnchor.classList.add('mm-active'); }
            if (childDropdown) { childDropdown.classList.add('mm-active'); }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== 'side-menu') {
              parent4El.classList.add('mm-show');
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== 'side-menu') {
                parent5El.classList.add('mm-active');
                const childanchor = parent5El.querySelector('.is-parent');
                if (childanchor && parent5El.id !== 'side-menu') { childanchor.classList.add('mm-active'); }
              }
            }
          }
        }
      }
    }

  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem): boolean {

    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  hasSubItems(item: SidebarList): boolean {

    return item?.sub_items !== undefined && item?.sub_items !== null ? item?.sub_items.length > 0 : false;
  }

  hasAccess(item: MenuItem): boolean {


    const dataSession = localStorage.getItem('dataUser');
    if (!dataSession) return false;
    const data = JSON.parse(dataSession);

    if (item?.roles && data) {
      return item.roles.some(r => r === data.user.tipo_usuario)
    }

    return false

    // // if(item.link)
    // const authAccess = new AccessControlService();
    // // return authAccess.userHasAccess(item.link)
    // //   if (item.isTitle) return true

    // let subItems = item.subItems !== undefined ? item.subItems.length > 0 : false


    // if (subItems) {
    //   console.log('subitems', item)

    //   let filterAccess = item.subItems.filter((s: any[]) =>  s.some(i => authAccess.userHasAccess(i.link)))
    //   return filterAccess.length > 0
    // } else {

    //   // console.log(item)

    //   return authAccess.userHasAccess(item.link)
    // }


  }

  hasSidebarAccess(item: MenuItem): boolean {

    return true;
  }
}
