import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormDataJsonComponent } from './forms/form-data-json/form-data-json.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/core/core.module';
import { FormSidebarComponent } from './forms/form-sidebar/form-sidebar.component';
import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FormIdentificationComponent as FormIdentification} from './forms/form-identification/form-identification.component';
import { FormArrayBankAccountComponent as FormArrayBankAccount} from './forms/form-array-bank-account/form-array-bank-account.component';
import { FormArrayContactComponent as FormArrayContact } from './forms/form-array-contact/form-array-contact.component';
import { FormCompanyComponent as FormCompany } from './forms/form-company/form-company.component';
import { FormPersonComponent as FormPerson } from './forms/form-person/form-person.component';
import { PlaceholderTableComponent } from './placeholders/placeholder-table/placeholder-table.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PlaceholderCardComponent } from './placeholders/placeholder-card/placeholder-card.component';
import { PlaceholderSidebarComponent } from './placeholders/placeholder-sidebar/placeholder-sidebar.component';
import { FormUploadComponent } from './forms/form-upload/form-upload.component';
import { NgxDropzoneModule } from 'ngx-dropzone';


@NgModule({
  declarations: [
    ModalComponent,
    FormDataJsonComponent,
    FormSidebarComponent,
    FormArrayContact,
    FormArrayBankAccount,
    FormCompany,
    FormPerson,
    FormIdentification,
    PlaceholderTableComponent,
    PlaceholderCardComponent,
    PlaceholderSidebarComponent,
    FormUploadComponent,
    
  ],
  imports: [
    CommonModule,
    CoreModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgStepperModule,
    CdkStepperModule,
    NgSelectModule,
    UiSwitchModule,
    ProgressbarModule,
    NgxDropzoneModule
  ],
  exports:[
    ModalComponent,
    FormDataJsonComponent,
    FormSidebarComponent,
    // FormClientComponent
    FormArrayBankAccount,
    FormCompany,
    FormPerson,
    FormIdentification,
    PlaceholderTableComponent,
    PlaceholderCardComponent,
    FormUploadComponent
  ]
})
export class ComponentsModule { }
