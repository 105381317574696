import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-placeholder-sidebar',
  templateUrl: './placeholder-sidebar.component.html',
  styleUrls: ['./placeholder-sidebar.component.scss']
})
export class PlaceholderSidebarComponent {

  @Input() showLoading: boolean = false;
  @Input() rows: number = 0;
  @Input() showSubItems: boolean = false;
  loadRandom: number = 0;


  /**
* OBTENER RANGO A PARTIR DE UN VALOR
* @param value 
* @returns 
*/
  getRange(value: number): number[] {
    return Array.from({ length: value }, (_, index) => index);
  }

}
