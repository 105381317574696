import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/config/language.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ConfigService, GroupService, InactivityService, SessionUserService, SweetAlertService, UserService } from 'src/app/core/services';
import { GroupList, ResponseApi, User, UserList } from 'src/app/core/models';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {

  @ViewChild('center', { static: false }) center?: ModalDirective;

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;

  dataUser: User;

  PATH_URL_FILES: string = '';

  listGroup: GroupList[] = [];
  groupIdSelected: any = '';
  groupSelected: any = null;
  nameGroupSelected: string = 'Sin coordinación';

  loadingGroup: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private _authService: AuthService,
    private _sessionUserService: SessionUserService,
    private _userService: UserService,
    private _configService: ConfigService,
    private _groupService: GroupService,
    private _sweetAlertService: SweetAlertService,
    private inactivityService: InactivityService,
  ) { 
    this.PATH_URL_FILES = this._configService.urlFiles + 'user/';
  }

  listLang = [
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/spain.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.getDataUserSession();


    // SESSION
    this.subscription.add(
      this._sessionUserService.getDataUser()
        .subscribe((data: UserList) => {
          this.dataUser = data;
          if(data){
            this.getDataUser(data.id);
            this.apiGroupFilterByUserId(data.id);
          }
        })
    );

    // LISTA DE USUARIOS
    // this.subscription.add(
    //   this._groupService.listObserver$
    //     .subscribe((list: GroupList[]) => {
    //       this.listGroup = list;
    //     })
    // );

    // GRUPO SELECCIONADO
    this.subscription.add(
      this._groupService.getDataShared()
        .subscribe((data: GroupList) => {
          this.groupSelected = data;
          if(data){
            this.groupIdSelected = data.id;
            this.nameGroupSelected = data.nombre;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  private getDataUser(id: number) {
    // this._sweetAlertService.loadingUp()
    this._userService.getById(id).subscribe((response: ResponseApi) => {
      if (response.code == 200) {
        this.dataUser = UserList.cast(response.data[0]);
      }

      if (response.code == 422) {
        if (response.errors) {

        }
      }

      if (response.code == 500) {
        if (response.errors) {

        }
      }
    }, (error: ResponseApi) => {
      console.log(error);
    });
  }

  private apiGroupFilterByUserId(userId: number) {
    // this._sweetAlertService.loadingUp()
    this._groupService.filterByUser(userId).subscribe((response: ResponseApi) => {
      if (response.code == 200) {
        this.listGroup = response.data;

        if(this.dataUser.tipo_usuario == 'PROPIETARIO'  || this.dataUser.tipo_usuario == 'SISTEMA' || this.dataUser.tipo_usuario == 'ADMINISTRADOR'){
          this.nameGroupSelected = 'Todos';
        } else {
          const grupo = this.listGroup.length  > 0? this.listGroup[this.listGroup.length - 1]: null;
          if(grupo){
            this.nameGroupSelected = grupo.nombre;
            this.groupIdSelected = grupo.id;
            this.groupSelected = grupo;
            this._groupService.setDataShared(grupo);
            
            setTimeout(() => {
              if(this.listGroup.length > 1){
                this.center?.show()
              }
             }, 250);
          }

        }
      }

      if (response.code == 422) {
        if (response.errors) {

        }
      }

      if (response.code == 500) {
        if (response.errors) {

        }
      }
    }, (error: ResponseApi) => {
      console.log(error);
    });
  }


  getDataUserSession() {
    const dataSession = localStorage.getItem('dataUser');
    if (dataSession) {
      const data = JSON.parse(dataSession);
      this.dataUser = data.user;

      const {user, person} = data;
      this._sessionUserService.setDataPerson(person);
      this._sessionUserService.setDataUser(user);
    }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (this.dataUser) {
      this._authService.logout(this?.dataUser?.id).subscribe((response: ResponseApi) => {
        if (response.code == 200) {
          this.router.navigate(['/account/login']);
        }
      });
    } else {
      this.router.navigate(['/account/login']);
    }

    // console.log("LOGOUT EJECUTADO ir a '/account/login'")
    this._sessionUserService.setClearData(true);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }


  // SELECCIÓN DE GRUPO
  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  onChangeGroup(event: any){
    // console.log(event.target.value);
    // console.log("GRUPO:", this.groupIdSelected);

    let idGrupo = event.target.value;
    const grupo = this.listGroup.find((obj: any) => obj.id == idGrupo);
    this._groupService.setDataShared(grupo);

    if(grupo){
      this.nameGroupSelected = grupo.nombre;
    } else {
      this.nameGroupSelected = 'Sin coordinación';
    }
  }

  onSelectGroup(){
    if(!this.groupIdSelected ||  this.groupIdSelected == ''){
      this._sweetAlertService.showTopEnd({ type: 'error', title: 'Invalido!', message: 'Por favor seleccione una coordinación valida', timer: 2500 });
      return;
    }

    let idGrupo = this.groupIdSelected;
    const grupo = this.listGroup.find((obj: any) => obj.id == idGrupo); 
    this._groupService.setDataShared(grupo);
    this.center.hide();
  }

  openModalGroup(){
    this.center.show();
  }


  // BLOQUEO DE PANTALLA
  lockScreen(){
    this.inactivityService.lockScreen()
  }
}
