import { Component , HostListener, OnDestroy, OnInit} from '@angular/core';
import { InactivityService } from './core/services';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {

  detecteChange: boolean = true;

  private subscription: Subscription = new Subscription();

  constructor(
    private inactivityService: InactivityService,
    private cookieService: CookieService,
  ) {
    // inactivityService.startMonitoring()
  }

  // 'mousemove', 'keydown', 'scroll', 'click', 'touchstart'
  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:keydown')
  @HostListener('document:scroll')
  @HostListener('document:click')
  @HostListener('document:touchstart')
  handleUserActivity() {
    // this.inactivityService.detectMouseMove$.subscribe((detected: boolean) => {
    //   if(detected){
    //     this.inactivityService.resetTimer();
    //   }
    // })
  }
  
  ngOnInit() {
    // this.handleUserActivity();

    // this.inactivityService.getDetectChange()
    // .pipe(distinctUntilChanged())
    // .subscribe((detect: boolean) => {
    //   this.detecteChange = detect;
    // })
  }

  ngOnDestroy() {
    // this.inactivityService.stopMonitoring(); // Limpia los eventos al destruir el componente
  }



}
