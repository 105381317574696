export * from './type-document.service'
export * from './type-bank-account.service'
export * from './type-user.service'
export * from './type-service.service'
export * from './type-status.service'
export * from './manual.service'
export * from './advertisement.service'
export * from './promotion.service'
export * from './product.service'
export * from './group.service'
export * from './member.service'
export * from './campus.service'
export * from './ubigeo.service'
export * from './type-currency.service'
export * from './operator.service'
export * from './typification-call.service'
export * from './category.service'
export * from './brand.service'
export * from './service.service'