import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpProgressEvent
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ProgressService } from '../services';

@Injectable()
export class HttpProgressInterceptor implements HttpInterceptor {
  private startTime: number;

  constructor(private _progressService: ProgressService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const progressService = inject(ProgressService);
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        const progressData = this.calculateProgressAndRemainingTime(event);

        if(event.type === HttpEventType.UploadProgress && progressData){
          progressService.setUploadProgress(progressData.progress);
          progressService.setRemainingTime(progressData.timeRemaining)
          progressService.setDataProgress(progressData);
          // console.log(`Progreso de subida: ${progressData.progress}%`);
          // Aquí puedes emitir el progreso a un servicio o componente.
        }

        if(event.type == HttpEventType.DownloadProgress && progressData){
          progressService.setDownloadProgress(progressData.progress);
          progressService.setRemainingTime(progressData.timeRemaining);
          progressService.setDataProgress(progressData);
          // console.log(`Progreso de descarga: ${progressData.progress}%`);
          // Emitir progreso de descarga si es necesario.
        }

        if (event.type === HttpEventType.Response) {
          // Finaliza la solicitud, puedes emitir un valor de finalización si es necesario
          // progressService.completeAll();
          
        }

      })
    );

  }

  // Función para calcular el progreso y el tiempo restante
  private calculateProgressAndRemainingTime(event: HttpEvent<{progress: number, timeRemaining: string, type: HttpEventType}>) {
    if(event.type === HttpEventType.UploadProgress || event.type == HttpEventType.DownloadProgress){
      const progress = Math.round((100 * event.loaded) / (event.total || 1));

      // Si no se ha inicializado el tiempo de inicio, lo establecemos ahora.
      if (!this.startTime) {
        this.startTime = Date.now();
      }
      const elapsedTime = (Date.now() - this.startTime) / 1000; // Tiempo en segundos
      const speed = event.loaded / elapsedTime; // Velocidad en bytes/segundo
  
      const remainingBytes = (event.total || 0) - event.loaded;
      const timeRemaining = remainingBytes / speed; // Tiempo restante en segundos
  
      const hoursRemaining = Math.floor(timeRemaining / 3600);
      const minutesRemaining = Math.floor((timeRemaining % 3600) / 60);
      const secondsRemaining = Math.floor(timeRemaining % 60);
  
      const timeRemainingFormatted = `${hoursRemaining
        .toString()
        .padStart(2, '0')}:${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining
        .toString()
        .padStart(2, '0')}`;


      // if(progress == 100){
      //   setTimeout(() => {
      //   this._progressService.setClear(true)
      //   }, 250);
      // }
  
      return { progress, timeRemaining: timeRemainingFormatted, type:event.type };
    }

    return null
  }

}
