export * from './type-document.model'
export * from './type-status.model'
export * from './type-bank-account.model'
export * from './type-user.model'
export * from './type-service.model'
export * from './manual.model'
export * from './advertisement.model'
export * from './promotion.model'
export * from './product.model'
export * from './group.model'
export * from './member.model'
export * from './campus.model'
export * from './type-currency.model'
export * from './operator.model'
export * from './typification-call.model'
export * from './category.model'
export * from './brand.model'
export * from './service.model'
export * from './installation.model'
export * from './installation-client.model'