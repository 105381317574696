import { Model } from "../model";

export class Template extends Model{
  public id: number;
  public icono: string;
  public codigo: string;
  public nombre: string;
  public descripcion: string;
  public plantilla: string;
  public is_visible: boolean;
  public is_active: boolean;
  public atributos?: any[];

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.icono = this.icono || null;
    this.codigo = this.codigo || null;
    this.nombre = this.nombre || null;
    this.descripcion = this.descripcion || null;
    this.plantilla = this.plantilla || null;
    this.is_visible = this.is_visible || true;
    this.is_active = this.is_active || true;
    this.atributos = this.atributos || [];
  }

  public static cast(data: object): Template{
    const obj = new Template(data);
    return {
      id: obj.id,
      icono: obj.icono,
      codigo: obj.codigo,
      nombre: obj.nombre,
      descripcion: obj.descripcion,
      plantilla: obj.plantilla,
      is_visible: obj.is_visible,
      is_active: obj.is_active,
      atributos: obj.atributos,
    }
  }

  public static casts(dataArray: object[]): Template[]{
    return dataArray.map((data) => Template.cast(data));
  }
}

export class TemplateList extends Model{
  public id: number;
  public icono: string;
  public codigo: string;
  public nombre: string;
  public descripcion: string;
  public plantilla: string;
  public is_visible: boolean;
  public is_active: boolean;
  public atributos?: any[];

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.icono = this.icono || null;
    this.codigo = this.codigo || null;
    this.nombre = this.nombre || null;
    this.descripcion = this.descripcion || null;
    this.plantilla = this.plantilla || null;
    this.is_visible = this.is_visible || true;
    this.is_active = this.is_active || true;
    this.atributos = this.atributos || [];
  }

  public static cast(data: object): TemplateList{
    const obj = new TemplateList(data);

   return {
    id: obj.id,
    icono: obj.icono,
    codigo: obj.codigo,
    nombre: obj.nombre,
    descripcion: obj.descripcion,
    plantilla: obj.plantilla,
    is_visible: obj.is_visible,
    is_active: obj.is_active,
    atributos: obj.atributos,
    }
  }

  public static casts(dataArray: object[]): TemplateList[]{
    return dataArray.map((data) => TemplateList.cast(data));
  }
}
