import { Model } from "../model";

export class TypeService extends Model{
  public id: number;
  public plantillas_id: number;
  public codigo_plantilla: string;
  public nombre: string;
  public icono?: string;
  public descripcion: string;
  public is_active: boolean;
  public template: any;

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.plantillas_id = this.plantillas_id || null;
    this.codigo_plantilla = this.codigo_plantilla || null;
    this.nombre = this.nombre || null;
    this.icono = this.icono || null;
    this.descripcion = this.descripcion || null;
    this.is_active = this.is_active || true;
    this.template = this.template || null;
  }

  public static cast(data: object): TypeService{
    const typeUser = new TypeService(data);
    return {
      id: typeUser.id,
      plantillas_id: typeUser.plantillas_id,
      codigo_plantilla: typeUser.codigo_plantilla,
      nombre: typeUser.nombre,
      icono: typeUser.icono,
      descripcion: typeUser.descripcion,
      is_active: typeUser.is_active,
      template: typeUser.template,
    }
  }

  public static casts(dataArray: object[]): TypeService[]{
    return dataArray.map((data) => TypeService.cast(data));
  }
}

export class TypeServiceList extends Model{
  public id: number;
  public plantillas_id: number;
  public codigo_plantilla: string;
  public nombre: string;
  public icono?: string;
  public descripcion: string;
  public is_active: boolean;
  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public template: any;

  constructor(data?: object){
    super(data);
    this.id = this.id || 0;
    this.nombre = this.nombre || '';
    this.icono = this.icono || '';
    this.plantillas_id = this.plantillas_id || null;
    this.codigo_plantilla = this.codigo_plantilla || null;
    this.descripcion = this.descripcion || '';
    this.is_active = this.is_active || false;
    this.created_at = this.created_at || '';
    this.updated_at = this.updated_at || '';
    this.deleted_at = this.deleted_at || '';
    this.template = this.template || null;
  }

  public static cast(data: object): TypeServiceList{
    const typeUser = new TypeServiceList(data);
    return {
      id: typeUser.id,
      plantillas_id: typeUser.plantillas_id,
      codigo_plantilla: typeUser.codigo_plantilla,
      nombre: typeUser.nombre,
      icono: typeUser.icono,
      descripcion: typeUser.descripcion,
      is_active: typeUser.is_active,
      created_at: typeUser.created_at,
      updated_at: typeUser.updated_at,
      deleted_at: typeUser.deleted_at,
      template: typeUser.template,
    }
  }

  public static casts(dataArray: object[]): TypeServiceList[]{
    return dataArray.map((data) => TypeServiceList.cast(data));
  }
}