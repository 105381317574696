import { Injectable } from '@angular/core';
import { rolesRoutes } from '../../../roles-routes.config';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class AccessControlService {

  constructor(private cookieService:CookieService) {

  }


  
  isLoggedIn(): boolean {
    return this.cookieService.check('token_auth');
  }

  hasAnyPermission(expectedRoles: string[]): boolean {
    const dataSession = localStorage.getItem('dataUser');
    const data = JSON.parse(dataSession);
    const tipoUsuario = data?.user?.tipo_usuario;

    const hasRole = expectedRoles.find((item: string) => item.toUpperCase() == tipoUsuario.toUpperCase());
    return hasRole? true: false;
  }

  public userHasAccess(route: string): boolean {
    const dataSession = localStorage.getItem('dataUser');
    if(!dataSession) return false;    

    const data = JSON.parse(dataSession);
    const tipoUsuario = data?.user?.tipo_usuario;
    
    if (tipoUsuario === 'PROPIETARIO' || tipoUsuario === 'ADMINISTRADOR') return true
    
    let routesUser = rolesRoutes[tipoUsuario];    
    if(!routesUser) return false;
    return routesUser.some((item: string) => item == route);
  }

}
