import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private activity$: Observable<any>;
  private inactivityTimer: any;
  private inactivityTimeout = 5 * 60000; // 30 segundos
  public detected: Subject<boolean> = new Subject<boolean>();
  public inactivityDetected: Subject<boolean> = new Subject<boolean>();

  private detectMouseMoveSubject = new BehaviorSubject<boolean>(true);
  detectMouseMove$ = this.detectMouseMoveSubject.asObservable();

  private detectSubject = new BehaviorSubject<boolean>(true);

  constructor(private cookieService: CookieService) {
    this.activity$ = fromEvent(document, 'mousemove');
    this.activity$.pipe(debounceTime(this.inactivityTimeout)).subscribe(() => {
      this.inactivityDetected.next(true);
      this.cookieService.set('inactivity_detected', 'true', 7, '/', window.location.hostname, true, 'Strict');
      // console.log("INACTIVIDAD DETECTADO...")
    });
  }

  resetTimer() {
    // console.log("DETECTANDO MOVIMIENTO...")
    clearTimeout(this.inactivityTimer);
    this.inactivityTimer = setTimeout(() => {
      this.inactivityDetected.next(true);
    }, this.inactivityTimeout);
  }


  setDetectMouseMove(enabled: boolean): void {
    this.detectMouseMoveSubject.next(enabled);
  }

  setDetectChange(enabled: boolean): void {
    this.detectSubject.next(enabled);
  }

  getDetectChange() {
    return this.detectSubject.asObservable();
  }
}
