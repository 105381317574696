import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { FileUploadUtil } from 'src/app/core/helpers';
import { SweetAlertService } from 'src/app/core/services';

@Component({
  selector: 'app-form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.scss']
})
export class FormUploadComponent implements OnInit, OnDestroy, OnChanges {

  @Output() emitFiles = new EventEmitter<File[]>();

  uploadFiles: File[] = [];
  uploadPreview: any[] = [];
  uploadPreviewFilter: any[] = [];

  activeFilter: string | null = null; // Almacena el filtro activo
  countedFileByType: any = null;

  listFileLightbox: any = [];

  constructor(
    private _sweetAlertService: SweetAlertService,
    private lightbox: Lightbox,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }



  /**
   * **************************************************************
   * SELECCIÓN DE ARCHIVOS
   * @param fileInput elemento input
  * **************************************************************
  */
  async onFilesSelect(event: any) {
    let maxTotalSize = 100 * 1024 * 1024; // 90 MB
    let maxFileUpload = 20;
    let byteToText = FileUploadUtil.formatFileSize(maxTotalSize);
    for (const file of event.addedFiles) {
      if (file.size > maxTotalSize) {
        this._sweetAlertService.showErrorAlert(`El archivo ${file.name} excede el tamaño máximo permitido ${byteToText}`)
        return;
      }
    }

    if (this?.uploadPreview?.length + event?.addedFiles?.length > maxFileUpload) {
      this._sweetAlertService.showErrorAlert(`La cantidad permitido es menor o igual a ${maxFileUpload} archivos`)
      return;
    }

    const selectedFiles = event.addedFiles.map((f: File) => {
      return {
        file: f,
        src: URL.createObjectURL(f),
        type: this.getFileType(f)
      }
    })


    this.uploadPreview.push(...selectedFiles);
    this.filterByType(this.activeFilter, false)
    this.countFileByType()

    // EMITIR ARCHIVOS SELECCIONADOS
    const uploaded = this.uploadPreview.map((f: any) => f.file as File);
    this.emitFiles.emit(uploaded)
  }

  onRemove(event: any) {
    this.uploadPreview.splice(this.uploadPreview.indexOf(event), 1);
    this.filterByType(this.activeFilter, false)
    this.countFileByType()

    // EMITIR ARCHIVOS SELECCIONADOS
    const uploaded = this.uploadPreview.map((f: any) => f.file);
    this.emitFiles.emit(uploaded)
  }

  getFileType(file: File): string {
    const typeFile = FileUploadUtil.getFileType(file);
    if (typeFile == 'archive' || typeFile == 'data' || typeFile == 'binary') {
      return 'other'
    }
    return typeFile;
  }

  formatFileSize(bytes: number = 0): string {
    return FileUploadUtil.formatFileSize(bytes);
  }

  // MOSTRAR ARCHIVOS EN LIGHTBOX
  showFileLightBox(index: number) {
    // open lightbox
    this.lightbox.open(this.listFileLightbox, index, {
      wrapAround: false,
      showRotate: true,
      showImageNumberLabel: true,
      showZoom: true,
    });
  }


  private countFileByType() {
    this.countedFileByType = this.uploadPreview.reduce((acc, file) => {
      acc[file.type] = (acc[file.type] || 0) + 1;
      return acc
    }, {})

    // return this.uploadPreview.filter((f: any) => f.type == type).length
  }

  filterByType(type: string, change: boolean = true) {
    this.activeFilter = this.activeFilter === type && change == true ? null : type;
    if (this.activeFilter) {
      this.uploadPreviewFilter = this.uploadPreview.filter((f: any) => f.type == type);
    } else {
      this.uploadPreviewFilter = this.uploadPreview;
    }

    this.listFileLightbox = this.uploadPreviewFilter.filter((f: any) => f.type == 'image')
      .map((row: any) => {
        return {
          src: row.src,
          caption: row.file?.name,
          thumb: row.src,
        }
      });
  }
}
