import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Sidebar } from 'src/app/core/models/api/settings';
import { ApiErrorFormattingService, FormService, SweetAlertService } from 'src/app/core/services';

@Component({
  selector: 'app-form-sidebar',
  templateUrl: './form-sidebar.component.html',
  styleUrls: ['./form-sidebar.component.scss']
})
export class FormSidebarComponent implements OnInit, OnDestroy, OnChanges {

  // Datos de entrada
  @Input() data: Sidebar = null;
  @Input() submitted: boolean = false;

  // Datos de salida
  @Input() isSubItem: boolean = false;
  @Input() hiddenButtons: boolean = false;
  @Output() submit = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  // MOSTRAR LISTA
  showList: boolean = true;
  isNewData: boolean = true
  activeBadge: boolean = false;;
  // submitted: boolean = false;
  sidebarForm: FormGroup;


  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private _formService: FormService,
    private _apiErrorFormattingService: ApiErrorFormattingService,
    private _sweetAlertService: SweetAlertService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {

    this.initForm()
    this.onChangeData();

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.firstChange) {
      this.onChangeData();
    }
  }


  // DATOS EMITIDOS
  onChangeData() {
    if (this.sidebarForm) {

      if (this.data) {
        this.sidebarForm.setValue({...Sidebar.cast(this.data), 
        color_badge: [this.data?.badge?.color || '', [Validators.nullValidator]],
        active_badge: [this.data?.badge?.visible || false, [Validators.nullValidator]]});
        this.activeBadge = this.data?.badge?.visible;
        this.isNewData = false; 
      } else {
        // this.formDataList.push(this.newField());
        this.sidebarForm.setValue({...new Sidebar(), color_badge: ['', [Validators.nullValidator]],active_badge: [false, [Validators.nullValidator]]});
        this.isNewData = true;
      }
    }
  }


  /**
* Form data get
*/
  get form() {
    return this.sidebarForm.controls;
  }

  /**
   * INICIAR FORMULARTO CON LAS VALIDACIONES
   * @param model 
   */
  private initForm() {
    const sidebar = new Sidebar();
    const formGroupData = this.getFormGroupData(sidebar);
    this.sidebarForm = this.formBuilder.group(formGroupData);
    if(this.isSubItem){
      this.sidebarForm.get('icono').setValidators([Validators.nullValidator]);
    }
  }

  /**
   * CREAR VALIDACIONES DEL FORMGROUP
   * @param model 
   * @returns 
   */
  private getFormGroupData(model: Sidebar): object {
    return {
      ...this._formService.modelToFormGroupData(model),
      icono: [model?.icono || '', [Validators.nullValidator, Validators.maxLength(255)]],
      codigo: [model?.codigo || '', [Validators.nullValidator, Validators.maxLength(20)]],
      label: [model?.label || '', [Validators.required, Validators.maxLength(80)]],
      titulo: [model?.titulo || '', [Validators.nullValidator, Validators.maxLength(80)]],
      descripcion: [model?.descripcion || '', [Validators.nullValidator, Validators.maxLength(999)]],
      color_badge: ['', [Validators.nullValidator, Validators.maxLength(999)]],
      active_badge: [false, [Validators.nullValidator, Validators.maxLength(999)]],
      is_title: [model?.is_title || false, [Validators.nullValidator]],
      is_layout: [model?.is_layout || false, [Validators.nullValidator]],
      is_active: [model?.is_active || true, [Validators.nullValidator]],
      order: [model?.order || 1, [Validators.required]],
      link: [model?.link || '', [Validators.required, Validators.maxLength(150)]],
      sub_items: [model?.sub_items || [], [Validators.nullValidator]],
      roles: [model?.roles || [], [Validators.nullValidator]],
    }
  }



  /**
  * ************************************************************
  * EMITIR EL VALOR DEL FORMULARIO
  * ************************************************************
  */
  onSubmit() {
    this.submitted = true;
    if (this.sidebarForm.invalid) {
      this._sweetAlertService.showTopEnd({ title: 'Validación de datos', message: 'Campos obligatorios vacíos', type: 'warning', timer: 1500 });
      this.submit.emit({ emit: true, values: [] });
    } else {
      const values = this.sidebarForm.value;
      values.badge = {
        color: values?.color_badge || '',
        visible: this.activeBadge
      };

      delete values.color_badge;
      delete values.active_badge;

      // this.submit.emit({ emit: true, values });
      if(this.isNewData){
        this.submit.emit({ emit: true, data: values, process: 'nuevo'});
      } else {
        this.submit.emit({ emit: true, data: values, process: 'editado'});
      }

      this.onReset()
    }
  }

  onCancel() {
    this.onReset();
    this.cancel.emit({ message: 'Cancelado' });
  }

  onReset() {
    this.submitted = false;
    this.isNewData = true;
    this.activeBadge = false;
    this.sidebarForm.reset();
  }


}
