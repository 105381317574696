import { Model } from "./model";
import { UserList } from "./user.model";

export class Faq extends Model{
  public id?: number;
  public pregunta?: string;
  public respuesta?: string;
  public categoria?: string;
  public prioridad?: string;
  public views?: number;
  public tags?: any;
  public archivos?: any;
  public color?: string;
  public is_active?: boolean | number;

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.pregunta = this.pregunta || null;
    this.respuesta = this.respuesta || null;
    this.categoria = this.categoria || null;
    this.prioridad = this.prioridad || null;
    this.views = this.views || null;
    this.archivos = this.archivos || null;
    this.color = this.color || null;
    this.is_active = this.is_active || 1;
  }

  public static cast(data: object): Faq{
    const obj = new Faq(data);
    return {
      id: obj.id, 
      pregunta: obj.pregunta,
      respuesta: obj.respuesta,
      categoria: obj.categoria,
      prioridad: obj.prioridad,
      views: obj.views,
      archivos: obj.archivos,
      color: obj.color,
      is_active: obj.is_active
    };
  }

  public static casts(dataArray: object[]): Faq[]{
    return dataArray.map((data) => Faq.cast(data));
  }
}

export class FaqList extends Model{
  public id: number;
  public pregunta?: string;
  public respuesta?: string;
  public categoria?: string;
  public prioridad?: string;
  public views?: number;
  public tags?: any;
  public archivos?: FileFaq[];
  public color?: string;
  public is_active?: boolean | number;
  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public user_create?: UserList;
  public user_update?: UserList;

  constructor(data?: object){
    super(data);
    this.pregunta = this.pregunta || null;
    this.respuesta = this.respuesta || null;
    this.categoria = this.categoria || null;
    this.prioridad = this.prioridad || null;
    this.views = this.views || null;
    this.archivos = this.archivos || null;
    this.color = this.color || null;
    this.is_active = this.is_active || 1;
    this.created_at = this.created_at || null;
    this.updated_at = this.updated_at || null;
    this.deleted_at = this.deleted_at || null;
    this.user_create = this.user_create || null;
    this.user_update = this.user_update || null;
  }

  public static cast(data: object): FaqList{
    const obj = new FaqList(data);
    return {
      id: obj.id, 
      pregunta: obj.pregunta,
      respuesta: obj.respuesta,
      categoria: obj.categoria,
      prioridad: obj.prioridad,
      views: obj.views,
      archivos: obj.archivos,
      color: obj.color,
      is_active: obj.is_active,
      created_at: obj.created_at,
      updated_at: obj.updated_at,
      deleted_at: obj.deleted_at,
      user_create: obj.user_create,
      user_update: obj.user_update,
    };
  }

  public static casts(dataArray: object[]): FaqList[]{
    return dataArray.map((data) => FaqList.cast(data));
  }
}


export class FileFaq extends Model{
  public name: string;
  public path: string;
  public type: string;
  public fileType?: string;
  public size: number;

  constructor(data?: object){
    super(data);
    this.name = this.name || null;
    this.path = this.path || null;
    this.type = this.type || null;
    this.fileType = this.fileType || null;
    this.size = this.size || 0;
  }

  public static cast(data: object): FileFaq{
    const obj = new FileFaq(data);
    return {
      name: obj.name,
      path: obj.path,
      type: obj.type,
      fileType: obj.fileType,
      size: obj.size,
    };
  }

  public static casts(dataArray: object[]): FileFaq[]{
    return dataArray.map((data) => FileFaq.cast(data));
  }
}