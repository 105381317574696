
export const rolesRoutes = {
    // 'ADMINISTRADOR': ['/main', '/sale', '/calendar'],
    'SISTEMA': [
        '/main', 
        '/sale', 
        '/calendar',
        '/maintenances/manual', 
        '/maintenances/advertisement', 
        '/maintenances/group', 
        '/maintenances/promotion', 
        '/maintenances/types/',
        '/settings/permission',
        '/settings/type_user_permission',
        '/maintenances/types/typeCurrency',
        '/maintenances/types/typeBankAccount',
        '/maintenances/types/typeDocument',
        '/maintenances/types/typeUser',
        '/maintenances/types/typeStatus',
        '/maintenances/types/typeService',
        '/reports/all',
        '/reports/by_commercial',
        '/reports/by_coordinator'
    ],
    'SUPERVISOR': [
        '/main', 
        '/sale', 
        '/calendar',
        '/maintenances/user',
        '/maintenances/group',
        '/maintenances/product',
        '/maintenances/promotion',
        '/reports/all',
        '/reports/by_commercial',
        '/reports/by_coordinator'
    ],
    'VENDEDOR': ['/main', '/maintenances/manual','/sale', '/calendar'],
    'COORDINADOR': ['/main', '/sale', '/calendar', '/maintenances/manual', '/maintenances/advertisement', '/maintenances/group', '/maintenances/promotion', 'reports/by-commercial'],
    'BACKOFFICE': ['/main', '/sale', '/calendar', '/maintenances/manual'],
    'EDITOR': ['/main','/maintenances/manual', '/maintenances/advertisement'],

};
