import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  private activity$: Observable<any>;
  private timeoutId: any;
  private inactivityTimer: any;
  private inactivityTimeout = 0.5 * 60000; // 
  public detected: Subject<boolean> = new Subject<boolean>();
  public inactivityDetected: Subject<boolean> = new Subject<boolean>();

  private detectMouseMoveSubject = new BehaviorSubject<boolean>(true);
  detectMouseMove$ = this.detectMouseMoveSubject.asObservable();

  private detectSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private cookieService: CookieService,
    private router: Router, 
    private ngZone: NgZone
  ) {
    // this.activity$ = fromEvent(document, 'mousemove');
    // this.activity$.pipe(debounceTime(this.inactivityTimeout)).subscribe(() => {
    //   this.inactivityDetected.next(true);
    //   this.cookieService.set('inactivity_detected', 'true', 7, '/', window.location.hostname, true, 'Strict');
    //   // console.log("INACTIVIDAD DETECTADO...")
    // });

    // this.startMonitoring()
  }

  resetTimer() {
    // console.log("DETECTANDO MOVIMIENTO...")
    if(this.inactivityTimer){
      clearTimeout(this.inactivityTimer);
    }

    this.inactivityTimer = setTimeout(() => {
      // this.inactivityDetected.next(true);
      this.ngZone.run(() => {
        this.inactivityDetected.next(true);
      });
    }, this.inactivityTimeout);
  }


  setDetectMouseMove(enabled: boolean): void {
    this.detectMouseMoveSubject.next(enabled);
  }

  setDetectChange(enabled: boolean): void {
    this.detectSubject.next(enabled);
  }

  getDetectChange() {
    return this.detectSubject.asObservable();
  }




  // *****************************************************
  // ! VERSIÓN MEJORADA - IMPLEMENTADA
  // *****************************************************
  private resetTimerV2() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.ngZone.run(() => {
        this.lockScreen();
      });
    }, this.inactivityTimeout);
  }

  startMonitoring() {
    ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'].forEach((event) => {
      window.addEventListener(event, () => this.resetTimerV2());
    });

    this.resetTimerV2();
  }

  stopMonitoring() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'].forEach((event) => {
      window.removeEventListener(event, () => this.resetTimerV2());
    });
  }

  lockScreen() {
    // GUARDAR RUTA ACTUAL
    const lastVisitedRoute = this.router.url;
    this.cookieService.set('last_visited_route', lastVisitedRoute, 7, '/', window.location.hostname, true, 'Strict');


    // BLOQUEAR PANTALLA
    this.inactivityDetected.next(true);
    this.cookieService.set('inactivity_detected', 'true', 7, '/', window.location.hostname, true, 'Strict');
    this.router.navigate(['/pages/lock-screen']);
  }

  unLockScreen(resetTimer: boolean = false) {
    // CAMBIAR ESTADO
    this.cookieService.set('inactivity_detected', 'false', 7, '/', window.location.hostname, true, 'Strict');
    this.inactivityDetected.next(false);

    // OBTENER ULTIMA URL
    let lastVisitedRoute = '/main'
    if(this.cookieService.check('last_visited_route')){
      lastVisitedRoute = this.cookieService.get('last_visited_route');
      this.cookieService.delete('last_visited_route', '/')
    }
    
    // REDIRECCIONAR URL
    this.router.navigate([lastVisitedRoute]);

    if(resetTimer){
      this.resetTimerV2(); // Reinicia el temporizador
    }
  }
}
