import { ClientList } from "./client.model";
import { GroupList, InstallationList, TypeCurrency, TypeStatusList } from "./maintenance";
import { Model } from "./model";
import { SaleDetailList } from "./sale-detail.model";
import { UserPersonList } from "./user.model";

export class Sale extends Model{
  public id?: number;
  public nro_orden?: number;
  public retailx_id?: number;
  public smart_id?: number;
  public direccion_smart_id?: number;
  public clientes_id?: number;
  public grupos_id?: number;
  public comentario?: string;
  public tipo_monedas_id?: number;
  public sub_total?: number;
  public descuento?: number;
  public descuento_total?: number;
  public total?: number;
  public is_active?: boolean;

  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.nro_orden = this.nro_orden || null;
    this.retailx_id = this.retailx_id || null;
    this.smart_id = this.smart_id || null;
    this.direccion_smart_id = this.direccion_smart_id || null;
    this.clientes_id = this.clientes_id || null;
    this.grupos_id = this.grupos_id || null;
    this.comentario = this.comentario || '';
    this.tipo_monedas_id = this.tipo_monedas_id || null;
    this.descuento = this.descuento || 0;
    this.descuento_total = this.descuento_total || 0;
    this.sub_total = this.sub_total || 0;
    this.total = this.total || 0;
    this.is_active = this.is_active || true;
  }

  public static cast(data: object): Sale{
    const obj = new Sale(data);
    return {
      id: obj.id,
      nro_orden: obj.nro_orden,
      retailx_id: obj.retailx_id,
      smart_id: obj.smart_id,
      direccion_smart_id: obj.direccion_smart_id,
      clientes_id: obj.clientes_id,
      grupos_id: obj.grupos_id,
      comentario: obj.comentario,
      is_active: obj.is_active,
      tipo_monedas_id: obj.tipo_monedas_id,
      descuento_total: obj.descuento_total,
      descuento: obj.descuento,
      sub_total: obj.sub_total,
      total: obj.total,
    }
  }

  public static casts(dataArray: object[]): Sale[]{
    return dataArray.map((data) =>Sale.cast(data));
  }
}

export class SaleList extends Model{
  public id?: number;
  public nro_orden?: number;
  public retailx_id?: string;
  public smart_id?: string;
  public direccion_smart_id?: string;
  public clientes_id?: number;
  public text_search?: string;
  public fecha?: string;
  public hora?: string;
  public comentario?: string;
  public tipo_monedas_id?: number;
  public type_currency?: TypeCurrency;
  public descuento_total?: number;
  public descuento?: number;
  public sub_total?: number;
  public total?: number;
  public is_active?: boolean;
  public created_at: string;
  public updated_at: string;
  public deleted_at: string;
  public client?: ClientList;
  public grupos_id?: number;
  public group?: GroupList;
  public user_create?: any;
  public user_update?: any;
  public type_status?: TypeStatusList;
  public sale_details?: SaleDetailList[];
  public installations?: InstallationList[];
  public installations_count?: any;
  public sale_details_count?: any;


  constructor(data?: object){
    super(data);
    this.id = this.id || null;
    this.nro_orden = this.nro_orden || null;
    this.retailx_id = this.retailx_id || null;
    this.smart_id = this.smart_id || null;
    this.direccion_smart_id = this.direccion_smart_id || null;
    this.clientes_id = this.clientes_id || null;
    this.text_search = this.text_search || null;
    this.fecha = this.fecha || null;
    this.hora = this.hora || null;
    this.comentario = this.comentario || '';
    this.is_active = this.is_active || true;
    this.created_at = this.created_at || '';
    this.updated_at = this.updated_at || '';
    this.deleted_at = this.deleted_at || '';
    this.client = this.client || null;
    this.grupos_id = this.grupos_id || null;
    this.user_create = this.user_create || null;
    this.user_update = this.user_update || null;
    this.type_status = this.type_status || null;
    this.group = this.group || null;
    this.sale_details = this.sale_details || [];
    this.installations = this.installations || [];
    this.installations_count = this.installations_count || null;
    this.sale_details_count = this.sale_details_count || null;
    this.tipo_monedas_id = this.tipo_monedas_id || null;
    this.type_currency = this.type_currency || null;
    this.descuento_total = this.descuento_total || 0;
    this.descuento = this.descuento || 0;
    this.sub_total = this.sub_total || 0;
    this.total = this.total || 0;
  }

  public static cast(data: object): SaleList{
    const obj = new SaleList(data);
    return {
      id: obj.id,
      nro_orden: obj.nro_orden,
      retailx_id: obj.retailx_id,
      smart_id: obj.smart_id,
      direccion_smart_id: obj.direccion_smart_id,
      clientes_id: obj.clientes_id,
      text_search: obj.text_search,
      fecha: obj.fecha,
      hora: obj.hora,
      comentario: obj.comentario,
      is_active: obj.is_active,
      created_at: obj.created_at,
      updated_at: obj.updated_at,
      deleted_at: obj.deleted_at,
      client: obj.client,
      group: obj.group,
      grupos_id: obj.grupos_id,
      user_create: obj.user_create,
      user_update: obj.user_update,
      type_status: obj.type_status,
      sale_details: obj.sale_details,
      installations: obj.installations,
      installations_count: obj.installations_count,
      sale_details_count: obj.sale_details_count,
      tipo_monedas_id: obj.tipo_monedas_id,
      type_currency: obj.type_currency,
      descuento_total: obj.descuento_total,
      descuento: obj.descuento,
      sub_total: obj.sub_total,
      total: obj.total,
    }
  }
  public static casts(dataArray: object[]): SaleList[]{
    return dataArray.map((data) => SaleList.cast(data));
  }
}
