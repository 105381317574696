import { HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Progress } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private uploadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private downloadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private remainingTime: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private data: BehaviorSubject<Progress> = new BehaviorSubject<Progress>(null);
  private clear: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private complete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor() {
    this.clear.asObservable()
      .subscribe((value: boolean) => {
        if (value) {
          this.data.next(null);
          this.uploadProgress.next(0);
          this.downloadProgress.next(0);
          this.remainingTime.next(null);
        }
      })

  }

  setDataProgress(progress: any): void {
    this.data.next(progress);
  }

  getDataProgress(): Observable<any> {
    return this.data.asObservable();
  }

  setUploadProgress(progress: number): void {
    this.uploadProgress.next(progress);
  }

  getUploadProgress(): Observable<number> {
    return this.uploadProgress.asObservable();
  }

  setDownloadProgress(progress: number): void {
    this.downloadProgress.next(progress);
  }

  getDownloadProgress(): Observable<number> {
    return this.downloadProgress.asObservable();
  }

  setRemainingTime(time: string): void {
    this.remainingTime.next(time);
  }

  getRemainingTime(): Observable<string> {
    return this.remainingTime.asObservable();
  }

  // LIMPIAR DATOS
  getClear(): Observable<boolean> {
    return this.clear.asObservable();
  }

  setClear(value: boolean) {
    this.clear.next(value);
  }

  completeAll() {
    this.data.complete();
    this.uploadProgress.complete();
    this.downloadProgress.complete();
    this.remainingTime.complete();
    this.clear.complete();
    this.complete$.complete();
  }
}
