import { Injectable } from '@angular/core';
import { FaqList, ResponseApi } from '../../models';
import { BehaviorSubject, Observable, Subject, catchError, distinctUntilChanged, filter, map, of, tap } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { ConfigService } from '../config';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private cachedData: ResponseApi; // Almacena los datos en caché
  private listSubject: BehaviorSubject<FaqList[]> = new BehaviorSubject<FaqList[]>([]);
  public listObserver$: Observable<FaqList[]> = this.listSubject.asObservable();

  private abortSignal$: Subject<any> = new Subject<any>();

  
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { 
    this.listObserver$
      .pipe(distinctUntilChanged())
      .subscribe((list: FaqList[]) => {
        if(this.cachedData){
          this.cachedData.data = list;
        }
    })
  }


  private get baseUrl(){
    return this.configService.apiUrl + 'faq';
  }

  private get requestOptions(){
    return this.configService.requestOptions;
  }
  private get requestOptionsEvents(){
    return this.configService.requestOptionsEvents;
  }

  /**
   * Listar todos los registros
   * @returns response, con el listado de los registros
   */
  public getAll(forceRefresh: boolean = false): Observable<ResponseApi> {
    if (this.cachedData && !forceRefresh) {
      this.changeArrayObserver(this.cachedData.data);
      return of(this.cachedData); // Devuelve datos en caché si están disponibles
    } else {
      return this.http.get(`${this.baseUrl}`).pipe(
        map((res: ResponseApi) => {
          this.changeArrayObserver(res.data);
          this.cachedData = res; // Almacena los datos en caché
          return res;
        })
      );
    }
  }


  public getPagination(data: any): Observable<ResponseApi> {
    const queryParams = new URLSearchParams();
    queryParams.set('data', JSON.stringify(data));
    const endpoint = `${this.baseUrl}/index?${queryParams.toString()}`;
    return this.http.get(endpoint).pipe(map((res: ResponseApi) => res))
  }


  public getById(id: any): Observable<ResponseApi> {
    const endpoint = `${this.baseUrl}/${id}`;
    return this.http.get(endpoint).pipe(map((res: ResponseApi) => res))
  }

  public register(data: any): Observable<ResponseApi>{
    const endpoint = `${this.baseUrl}`;
    return this.http.post(endpoint, data, this.requestOptionsEvents)
    .pipe(
      // Filtrar el evento de respuesta final
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      // Mapear la respuesta final a `ResponseApi`
      map((event: any) => event.body as ResponseApi)
    );
  }

  public update(data: any, id: any): Observable<ResponseApi>{
    const endpoint = `${this.baseUrl}/update/${id}`;
    return this.http.post(endpoint, data, this.requestOptionsEvents)
    .pipe(
      catchError(error => {
        // Manejar error si la solicitud fue abortada
        if (error.name === 'AbortError') {
          console.log('La solicitud fue cancelada');
        }
        return of(null); // Retorna un valor por defecto en caso de error
      })
    )
    .pipe(
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      map((event: any) => event.body as ResponseApi)
    )
  }

  public delete(id: any): Observable<ResponseApi>{
    const endpoint = `${this.baseUrl}/${id}`;
    return this.http.delete(endpoint).pipe(map((res: ResponseApi) => res))
  }

  public restore(id: any): Observable<ResponseApi>{
    const endpoint = `${this.baseUrl}/restore/${id}`;
    return this.http.get(endpoint).pipe(map((res: ResponseApi) => res))
  }


  /**
   * FUNCIONES PARA LOS OBSERVABLES
   */
  // SEÑAL DE CANCELACIÓN
  observeAbortSignal(): Observable<boolean> {
    return this.abortSignal$.asObservable();
  }

  nextAbortSignal(value: any = null) {
    this.abortSignal$.next(value);
  }

  
  // Método para agregar un nuevo objeto al array
  addObjectObserver(faqList: FaqList) {
    const currentData = this.listSubject.getValue();
    currentData.push(faqList);
    this.listSubject.next(currentData);
  }

  // Método para añadir mas items al array
  addArrayObserver(faqList: FaqList[]) {
    const currentData = this.listSubject.getValue();
    this.listSubject.next([...currentData, ...faqList]);
  }
  

  // Método para actualizar todo el array
  changeArrayObserver(faqList: FaqList[]) {
    this.listSubject.next(faqList);
  }

  // Método para modificar un objeto en el array
  updateObjectObserver(faqList: FaqList) {
    const currentData = this.listSubject.getValue();
    const index = currentData.findIndex(item => item.id === faqList.id);
    if (index !== -1) {
      currentData[index] = faqList;
      this.listSubject.next(currentData);
    }
  }

  // Método para quitar un objeto del array
  removeObjectObserver(id: any) {
    const currentData = this.listSubject.getValue();
    const updatedData = currentData.filter(item => item.id !== id);
    this.listSubject.next(updatedData);
  }
}
