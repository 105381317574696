import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(value: string | undefined, search: string): string {
    if (!value || !search) {
      return value || ''; // Si no hay valor o búsqueda, devuelve el texto tal cual
    }

    // Crear una expresión regular para buscar el texto
    const regex = new RegExp(`(${search})`, 'gi');
    return value.replace(regex, `<span class="highlight">$1</span>`); // Envolver coincidencias
  }

}
