import { Injectable } from '@angular/core';
import { ExportAsConfig, ExportAsService, SupportedExtensions } from 'ngx-export-as';

@Injectable({
  providedIn: 'root'
})
export class ExportLibService {

  private defaultConfig: ExportAsConfig = {
    elementIdOrContent: '',
    type: 'pdf', // Tipo predeterminado, puede cambiarse en la llamada
    options: {
      jsPDF: {
        orientation: 'portrait', // Orientación predeterminada
        unit: 'mm',
        format: 'a4',
        compress: true,
        scale: 1,
        fonts: [
          {
            family: 'Arial',
            style: 'normal',
            // src: 'path/to/arial.ttf' // Ruta a la fuente TrueType (ttf) incrustada, si es necesario
          }
        ]
      },
      pdfCallbackFn: this.pdfCallbackFn
    }
  }

  constructor(private exportAsService: ExportAsService,) { }

  exportAs(elementIdOrContent: string, type: SupportedExtensions, fileName: string, opt?: string): void {
    const config: ExportAsConfig = {...this.defaultConfig, type, elementIdOrContent}

    if(opt){
      config.options.jsPDF.orientation = opt;
    }

    this.exportAsService.save(config, fileName).subscribe({
      next: () => {
        // console.log('Exportación iniciada');
      },
      error: (err) => {
        // console.error('Error durante la exportación', err);
      }
    });

  }

  private pdfCallbackFn (pdf: any) {
    // example to add page number as footer to every page of pdf
    const noOfPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= noOfPages; i++) {
      pdf.setPage(i);
      pdf.text('Página ' + i + ' de ' + noOfPages, pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 10);
    }
  }
}
