import { Component , HostListener, OnDestroy, OnInit} from '@angular/core';
import { InactivityService } from './core/services';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  detecteChange: boolean = true;

  private subscription: Subscription = new Subscription();

  constructor(
    private inactivityService: InactivityService,
    private cookieService: CookieService,
  ) {}

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  handleUserActivity() {
    this.inactivityService.detectMouseMove$.subscribe((detected: boolean) => {
      if(detected){
        this.inactivityService.resetTimer();
      }
    })
  }
  
  ngOnInit() {
    this.handleUserActivity();

    this.inactivityService.getDetectChange()
    .pipe(distinctUntilChanged())
    .subscribe((detect: boolean) => {
      this.detecteChange = detect;
    })

  }



}
