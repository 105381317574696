
<div class="row" >
  <div class="col-md-12" *ngFor="let itemRow of getRange(rows); let iRow = index">
    <div class="card card-body placeholder-wave border">
      <h5 class="rounded placeholder-lg bg-primary col-md-5"></h5>
      <p class="rounded placeholder placeholder-sm bg- w-100"></p>
      <small class="rounded placeholder placeholder-xs bg- col-sm-3"></small>
      <hr>
    </div>
  </div>
</div>


  <div class="w-100 px-3 placeholder-glow">
    <progressbar
      [value]="loadRandom"
      [max]="98"
      [striped]="true"
      [type]="'primary'"
      [animate]="true"
      class="progress-striped placeholder active"
    ></progressbar>
    <div class="text-center mt-2">
      <h5 class="font-size-13">Cargando...</h5>
    </div>
  </div>
