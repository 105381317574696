import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { InactivityService } from '../services/shared/inactivity.service';
import { distinctUntilChanged, pipe } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

export const inactivityGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const inactivityService = inject(InactivityService);
  const cookieService = inject(CookieService);

  let isInactive = false;
  if(cookieService.check('inactivity_detected')){
    isInactive = Boolean(cookieService.get('inactivity_detected') == "true");
  }
  
  // DETECTAR INACTIVIDAD USUARIO
  inactivityService.inactivityDetected.pipe(distinctUntilChanged()).subscribe((inactive: boolean) => {
    isInactive = inactive;
    if (isInactive) {
      router.navigate(['/pages/lock-screen']);
      return false;
    } else {
      return true
    }
  });


  if (isInactive) {
    router.navigate(['/pages/lock-screen']);
    return false;
  }

  return true;
  
};
