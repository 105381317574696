<form (keydown.enter)="$event.preventDefault()" [formGroup]="sidebarForm" autocomplete="off">      
  <div class="row g-3" *ngIf="!isSubItem">
    <div class="col-lg-8 mb-3" *ngIf="!isSubItem && 'FORMS.FIELD_NAMES.icon.label' | translate as translatedLabel;">
      <label for="icono" class="form-label">{{translatedLabel}}</label>
      <input type="text" id="icono" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.icon.placeholder' | translate}}" formControlName="icono" [ngClass]="{ 'is-invalid': form['icono'].errors && (form.icono.dirty || form.icono.touched || submitted) }" />
      <div *ngIf="form['icono'].errors && (form.icono.dirty || form.icono.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['icono'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
      <span class="me-4">Link 1: <a href="https://pictogrammers.com/library/mdi/" target="_blank"
        rel="noopener noreferrer">MDI Icons</a></span>
      <span>Link 2: <a href="https://boxicons.com/" target="_blank" rel="noopener noreferrer">BOX Icons</a></span>

    </div>

    <div class="col-lg-4 mb-3" *ngIf="!isSubItem && 'FORMS.FIELD_NAMES.code.label' | translate as translatedLabel;">
      <label for="codigo" class="form-label">{{translatedLabel}}</label>
      <input type="text" id="codigo" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.code.placeholder' | translate}}" formControlName="codigo" [ngClass]="{ 'is-invalid': form['codigo'].errors && (form.codigo.dirty || form.codigo.touched || submitted) }" />
      <div *ngIf="form['codigo'].errors && (form.codigo.dirty || form.codigo.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['codigo'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
    </div>
  </div>      

  <div class="row g-3">
    <div class="col-lg-6 mb-3" *ngIf="'FORMS.FIELD_NAMES.label.label' | translate as translatedLabel;">
      <label for="label" class="form-label">{{translatedLabel}} *</label>
      <input type="text" id="label" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.label.placeholder' | translate}}" formControlName="label" [ngClass]="{ 'is-invalid': form['label'].errors && (form.label.dirty || form.label.touched || submitted) }" />
      <div *ngIf="form['label'].errors && (form.label.dirty || form.label.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['label'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
    </div>

    <!-- <div class="col-lg-6 mb-3" *ngIf="'FORMS.FIELD_NAMES.title.label' | translate as translatedLabel;">
      <label for="titulo" class="form-label">{{translatedLabel}}</label>
      <input type="text" id="titulo" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.title.placeholder' | translate}}" formControlName="titulo" [ngClass]="{ 'is-invalid': form['titulo'].errors && (form.titulo.dirty || form.titulo.touched || submitted) }" />
      <div *ngIf="form['titulo'].errors && (form.titulo.dirty || form.titulo.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['titulo'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
    </div> -->

    <div class="col-lg-6 mb-3" *ngIf="'FORMS.FIELD_NAMES.link.label' | translate as translatedLabel;">
      <label for="link" class="form-label">{{translatedLabel}} *</label>
      <input type="text" id="link" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.link.placeholder' | translate}}" formControlName="link" [ngClass]="{ 'is-invalid': form['link'].errors && (form.link.dirty || form.link.touched || submitted) }" />
      <div *ngIf="form['link'].errors && (form.link.dirty || form.link.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['link'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" *ngIf="'FORMS.FIELD_NAMES.description.label' | translate as translatedLabel;">
    <label for="descripcion" class="form-label">{{translatedLabel}}</label>
    <textarea id="descripcion" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.description.placeholder' | translate}}" formControlName="descripcion" [ngClass]="{ 'is-invalid': form['descripcion'].errors && (form.descripcion.dirty || form.descripcion.touched || submitted)}"></textarea>
    <div *ngIf="form.descripcion.errors && (form.descripcion.dirty || form.descripcion.touched || submitted)" class="invalid-feedback" align="left">
      <div *ngIf="form['descripcion'].errors['required']">
        {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
      </div>
    </div>
  </div>
  <div class="row g-3">
    <div class="col-md-3 mb-3" *ngIf="'FORMS.FIELD_NAMES.num_order.label' | translate as translatedLabel;">
      <label for="order" class="form-label">{{translatedLabel}} *</label>
      <input type="number" id="order" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.num_order.placeholder' | translate}}" formControlName="order" [ngClass]="{ 'is-invalid': form['order'].errors && (form.order.dirty || form.order.touched || submitted) }" />
      <div *ngIf="form['order'].errors && (form.order.dirty || form.order.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form['order'].errors['required']">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
      </div>
    </div>
    <div class="col-md-auto mb-3" *ngIf="'FORMS.FIELD_NAMES.active_badge.label' | translate as translatedLabel;">
      <label for="active_badge" class="form-label w-100">¿{{translatedLabel}}?</label>
      <ui-switch id="active_badge" name="active_badge" color="#556ee6" [(ngModel)]="activeBadge" formControlName="active_badge" uncheckedLabel="NO" checkedLabel="SI" size="small" class="me-1 w-100"></ui-switch>
    </div>

    <div class="col-md-3 mb-3" *ngIf="activeBadge && 'FORMS.FIELD_NAMES.color_badge.label' | translate as translatedLabel;">
      <label for="color_badge" class="form-label">{{translatedLabel}} *</label>
      <ng-select id="color_badge" name="color_badge"  formControlName="color_badge" [ngClass]="{ 'is-invalid': form.color_badge.errors && (form.color_badge.dirty || form.color_badge.touched || submitted) }">
        <ng-option [value]="''">{{'FORMS.FIELD_NAMES.color_badge.default_option' | translate}}</ng-option>
        <ng-option value="success">Verde</ng-option>
        <ng-option value="info">Celeste</ng-option>
        <ng-option value="primary">Azul</ng-option>
        <ng-option value="danger">Rojo</ng-option>
        <ng-option value="warning">Amarillo</ng-option>
      </ng-select>
      <div *ngIf="form.color_badge.errors && (form.color_badge.dirty || form.color_badge.touched || submitted)" class="invalid-feedback" align="left">
        <div *ngIf="form.color_badge.errors.required">
          {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
        </div>
        <div *ngIf="form.color_badge.errors.min">
          {{'FORMS.VALIDATION.min' | translate | replaceAttribute: ':attribute': translatedLabel | replaceAttribute: ':min': form.color_badge.errors.min.requiredLength}}
        </div>
      </div>
    </div>
    <div class="col-md-auto mb-3" *ngIf="!isSubItem && 'FORMS.FIELD_NAMES.is_title.label' | translate as translatedLabel;">
      <label for="is_title" class="form-label w-100">¿{{translatedLabel}}?</label>
      <ui-switch id="is_title" name="is_title" color="#556ee6" formControlName="is_title" uncheckedLabel="NO" checkedLabel="SI" size="small" class="me-1 w-100"></ui-switch>
    </div>
    <div class="col-md-auto  mb-3" *ngIf="'FORMS.FIELD_NAMES.is_visible.label' | translate as translatedLabel;">
      <label for="is_visible" class="form-label w-100">¿{{translatedLabel}}?</label>
      <ui-switch id="is_visible" name="is_visible" color="#556ee6" formControlName="is_visible" uncheckedLabel="NO" checkedLabel="SI" size="small" class="me-1 w-100"></ui-switch>
    </div>

  </div>    
  
  <div class="d-flex flex-row flex-wrap gap-2 justify-content-end" *ngIf="!hiddenButtons">
    <button type="button" class="btn btn-light" (click)="onCancel()">{{'MODALS.BUTTONS.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-primary" *ngIf="isNewData" (click)="onSubmit()">{{'FORMS.BUTTONS.ADD' | translate}}</button>
    <button type="button" class="btn btn-info"  *ngIf="!isNewData" (click)="onSubmit()">{{'FORMS.BUTTONS.UPDATE' | translate}}</button>
  </div>
</form>