export class Model {
  constructor(data?: object) {
    Object.assign(this, data);
  }
}

// export class Model {
//   constructor(data?: object) {
//     Object.assign(this, data);
//     if(data){
//       for (const key of Object.keys(data)) {
//         const value = data[key];
//         if (Array.isArray(value)) {
//           this[key] = value.map(item => (item && typeof item === 'object') ? { ...item } : item);
//         } else if (value && typeof value === 'object') {
//           this[key] = { ...value };
//         } else {
//           this[key] = value;
//         }
//       }
//     }
//   }
// }