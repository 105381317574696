<form [formGroup]="dataJsonForm" autocomplete="off">
  <div formArrayName="formList" class="bg-light px-2 py-2 rounded position-relative">

    <div *ngIf="showList">
      <div *ngFor="let fi of formDataList.controls; let i=index;">
        <div [formGroupName]="i" class="row g-2 bg-transparent border-top border-primary mb-3">
          <div class="w-100" style="z-index: 1500;">
            <span class="badge rounded-pill badge-soft-primary bg-primary text-white position-absolute bottom-0 start-0">{{i + 1}}</span>
            <button type="button" class="btn btn-danger btn-sm rounded-circle position-absolute top-0 end-0" 
              style="top: -18px !important;"
              *ngIf="visibleCloseBtn"
              (click)="removeField(i)"> <i class="fas fa-times" ></i></button>
          </div>
          <div class="col-lg-5 mb-2" *ngIf="'FORMS.FIELD_NAMES.key.label' | translate as translatedLabel;">
            <label for="clave{{i}}" class="form-label">{{translatedLabel}} (*)</label>
            <input type="text" maxlength="50" id="clave{{i}}" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.key.placeholder' | translate}} Ejemplo: nombre" formControlName="clave" [ngClass]="{ 'is-invalid': fi.get('clave').errors && (fi.get('clave').dirty || fi.get('clave').touched || submitted)}" />
            <div *ngIf="fi.get('clave').errors && (fi.get('clave').dirty || fi.get('clave').touched || submitted)" class="invalid-feedback" align="left">
              <div *ngIf="fi.get('clave').errors.required">
                {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
              </div>
              <div *ngIf="fi.get('clave').errors.maxlength">
                {{'FORMS.VALIDATION.maxlength' | translate | replaceAttribute: ':attribute': translatedLabel | replaceAttribute: ':maxlength': fi.get('clave').errors.maxlength.requiredLength}}
              </div>
              <div *ngIf="fi.get('clave').errors.minlength">
                {{'FORMS.VALIDATION.minlength' | translate | replaceAttribute: ':attribute': translatedLabel | replaceAttribute: ':minlength': fi.get('clave').errors.minlength.requiredLength}}
              </div>
            </div>
          </div>
          <div class="col-lg-7 mb-2" *ngIf="'FORMS.FIELD_NAMES.value.label' | translate as translatedLabel;">
            <label for="valor{{i}}" class="form-label me-2">{{translatedLabel}} (*)</label>
            <!-- <input type="text" maxlength="100" id="valor{{i}}" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.value.placeholder' | translate}}" formControlName="valor" [ngClass]="{ 'is-invalid': fi.get('valor').errors && (fi.get('valor').dirty || fi.get('valor').touched || submitted)}" /> -->
            <textarea name="valor" id="valor{{i}}" cols="30" rows="1" class="form-control" placeholder="{{'FORMS.FIELD_NAMES.value.placeholder' | translate}} Ejemplo: texto de prueba" formControlName="valor" [ngClass]="{ 'is-invalid': fi.get('valor').errors && (fi.get('valor').dirty || fi.get('valor').touched || submitted)}" ></textarea>
            <div *ngIf="fi.get('valor').errors && (fi.get('valor').dirty || fi.get('valor').touched || submitted)" class="invalid-feedback" align="left">
              <div *ngIf="fi.get('valor').errors.required">
                {{'FORMS.VALIDATION.required' | translate | replaceAttribute:':attribute': translatedLabel}}
              </div>
              <div *ngIf="fi.get('valor').errors.maxlength">
                {{'FORMS.VALIDATION.maxlength' | translate | replaceAttribute: ':attribute': translatedLabel | replaceAttribute: ':maxlength': fi.get('valor').errors.maxlength.requiredLength}}
              </div>
              <div *ngIf="fi.get('valor').errors.minlength">
                {{'FORMS.VALIDATION.minlength' | translate | replaceAttribute: ':attribute': translatedLabel | replaceAttribute: ':minlength': fi.get('valor').errors.minlength.requiredLength}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="position-relative">
      <strong type="button" (click)="showList = !showList" class="text-primary"><i class="mdi mdi-arrow-up-thick me-1" [ngClass]="{'mdi-eye-off': showList, 'mdi-eye': !showList}"></i> {{showList? 'Ocultar Campos': 'Mostrar Campos'}}</strong>
      <button type="button" (click)="addField()" class="btn btn-outline-primary rounded-pill position-absolute bottom-0 end-0" style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">
        <i class="fas fa-plus me-1 font-size-10" ></i> {{'FORMS.BUTTONS.ADD' | translate}}
      </button>
      <!-- <button type="button" class="btn btn-sm btn-success rounded-pill position-absolute bottom-0 end-0" (click)="addFieldIdentification()"><i class="fas fa-plus"></i></button> -->
    </div>
  </div>

  <!-- <div class="d-flex mt-3 flex-row flex-wrap gap-2 justify-content-end">
    <button type="button" class="btn btn-light" (click)="onCancel()">{{'MODALS.BUTTONS.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-success" (click)="onSubmit()">{{'FORMS.BUTTONS.SAVE' | translate}}</button>
  </div> -->
</form>

