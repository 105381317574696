import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileUploadUtil {
  constructor() {}

  public static handleFileUpload(
    fileInput: HTMLInputElement,
    allowedExtensions: string[] = [],
    maxFileSizeBytes: number = 0,
    maxFileSizePerType: Record<string, number> = {} // Objeto de configuración de peso máximo por tipo de archivo
  ): { files: File[], error: string | null } {
    const files: File[] = [];

    if (fileInput.files && fileInput.files.length > 0) {
      for (let i = 0; i < fileInput.files.length; i++) {
        const file = fileInput.files[i];
        const extension = file.name.split('.').pop().toLowerCase();

        if (allowedExtensions.length > 0 && !allowedExtensions.includes(extension)) {
          fileInput.value = ''; // Limpiar el input
          return { files: [], error: `Extensión no permitida para el archivo ${file.name}.` };
        }

        if (maxFileSizeBytes > 0 && file.size > maxFileSizeBytes) {
          fileInput.value = ''; // Limpiar el input
          return { files: [], error: `Tamaño de archivo demasiado grande para ${file.name}.` };
        }

        // Validación de peso máximo por tipo de archivo
        if (maxFileSizePerType.hasOwnProperty(extension) && file.size > maxFileSizePerType[extension]) {
          fileInput.value = ''; // Limpiar el input
          return {
            files: [],
            error: `Tamaño de archivo demasiado grande para ${file.name}.`,
          };
        }

        files.push(file);
      }
      return { files, error: null };
    }

    return { files: [], error: 'No se seleccionaron archivos.' };
  }

  /**
   * Esta función permite validar el tipo de archivo subido y el peso permitido
   * @param fileInput 
   * @param allowedExtensions 
   * @param maxFileSizeBytes 
   * @param maxFileSizePerType 
   * @returns objeto 
   */
  public static async handleFileUploadBase64(
    fileInput: HTMLInputElement,
    allowedExtensions: string[] = [],
    maxFileSizeBytes: number = 0,
    maxFileSizePerType: Record<string, number> = {} // Objeto de configuración de peso máximo por tipo de archivo
  ): Promise<{ files: { base64: string; file: File }[], error: string | null }> {
    const files: { base64: string; file: File }[] = [];

    if (fileInput.files && fileInput.files.length > 0) {
      for (let i = 0; i < fileInput.files.length; i++) {
        const file = fileInput.files[i];
        const extension = file.name.split('.').pop().toLowerCase();

        if (allowedExtensions.length > 0 && !allowedExtensions.includes(extension)) {
          fileInput.value = ''; // Limpiar el input
          return {
            files: [],
            error: `Extensión no permitida para el archivo ${file.name}.`,
          };
        }

        if (maxFileSizeBytes > 0 && file.size > maxFileSizeBytes) {
          fileInput.value = ''; // Limpiar el input
          return {
            files: [],
            error: `Tamaño de archivo demasiado grande para ${file.name}.`,
          };
        }

        // Validación de peso máximo por tipo de archivo
        if (maxFileSizePerType.hasOwnProperty(extension) && file.size > maxFileSizePerType[extension]) {
          fileInput.value = ''; // Limpiar el input
          return {
            files: [],
            error: `Tamaño de archivo demasiado grande para ${file.name}.`,
          };
        }

        // Obtener la representación Base64 del archivo
        const base64 = await this.readFileAsBase64(file);
        files.push({ base64, file });
      }
      return { files, error: null };
    }

    return { files: [], error: 'No se seleccionaron archivos.' };
  }

  private static async readFileAsBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          resolve(event.target.result as string);
        } else {
          reject('Error al leer el archivo como Base64.');
        }
      };
      reader.onerror = () => {
        reject('Error al leer el archivo como Base64.');
      };
      reader.readAsDataURL(file);
    });
  }

  
  public static formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  public static getFileType(file: File): string {
    const mimeType = file?.type;
  
    // Map de tipos MIME a categorías generales
    const typeMap: { [key: string]: string } = {
      // Imágenes
      'image/jpeg': 'image',
      'image/png': 'image',
      'image/gif': 'image',
      'image/bmp': 'image',
      'image/webp': 'image',
      'image/svg+xml': 'image',
  
      // Videos
      'video/mp4': 'video',
      'video/x-msvideo': 'video',
      'video/x-matroska': 'video',
      'video/webm': 'video',
      'video/quicktime': 'video',
  
      // Audios
      'audio/mpeg': 'audio',
      'audio/ogg': 'audio',
      'audio/wav': 'audio',
      'audio/aac': 'audio',
  
      // Documentos
      'application/pdf': 'document',
      'application/msword': 'document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
      'application/vnd.ms-excel': 'document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'document',
      'application/vnd.ms-powerpoint': 'document',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'document',
      'text/plain': 'document',
      'text/csv': 'document',
  
      // Archivos comprimidos
      'application/zip': 'archive',
      'application/x-rar-compressed': 'archive',
      'application/x-7z-compressed': 'archive',
      'application/x-tar': 'archive',
      'application/gzip': 'archive',
  
      // Otros formatos
      'application/json': 'data',
      'application/xml': 'data',
      'application/octet-stream': 'binary',
    };
  
    // Intentar una coincidencia exacta
    if (typeMap[mimeType]) {
      return typeMap[mimeType];
    }
  
    // Coincidencia basada en prefijos MIME comunes
    if (mimeType?.startsWith('image/')) return 'image';
    if (mimeType?.startsWith('video/')) return 'video';
    if (mimeType?.startsWith('audio/')) return 'audio';
  
    // Tipo desconocido
    return 'other';
  }
  
}
